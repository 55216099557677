import { Post } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
  GET_LOCATIONS: "[LOCATION - INFO] GET_LOCATIONS",
};

export const LoadLocationInfoTab = createAsyncAction(
  instanceActionTypes.GET_LOCATIONS,
  async ({ pageNo, pageSize, location }) => {
    const param = {
      pageSize: pageSize,
      from: pageNo,
      parameter: {
        location: location,
      },
    };
    return await Post(`location/SearchLocationBDA`, param, {});
  }
);
