import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
 

 

/**
 * PrivateRoute locks a route unless the isAuthenticated prop is true
 *
 * @param {Bool} isAuthenticated - if true, renders `compenent` prop. If false, redirects to `redirect` location
 * @param {Component} component - Component to render when accessible
 * @param {String} redirect - path to redirect to when inaccessible
 */
const PrivateRoute = ({
  component: Component,
  redirectPath,
  condition,
}) => {
  return (
    <Route     
      render={(routeProps) =>
        !condition ? (
          <Redirect to={redirectPath} />
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  );
};

export default PrivateRoute;
