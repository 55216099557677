import { AppButton } from "components/Button/Button";
import * as React from "react";
import { useForm, FormProvider } from "react-hook-form";
import SettlementAccount from "./settlementAccounts";

export const Scenario = ({ allData, setAllData }) => {
  const defaultValues = {
    settlementAccounts: Object.keys(allData.settlementAccounts).map(
      (val, index) => allData.settlementAccounts[index]
    ),
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState,
    setValue,
    watch,
    values,
  } = methods;
  const onSubmit = (data) => {
    console.log({ data });

    setAllData({
      ...allData,
      settlementAccounts: {
        ...data.settlementAccounts,
      },
    });
    // toggleSettlement()
  };
const settlement = watch('settlementAccounts')
  console.log(formState.errors);
  console.log(settlement);
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-6">
          <SettlementAccount
            {...{
              control,
              register,
              formState,
              defaultValues,
              getValues,
              setValue,
              
            }}
          />

          {/* <input type="submit" disabled={!formState.isValid} /> */}
          {/* <div className="mt-8 flex gap-4">
                       
                        <AppButton type="button" label="Save"  />
                    </div> */}
        </form>
      </FormProvider>
    </div>
  );
};
// const {
//     register,
//     handleSubmit,
//     formState,
//     reset,
//     watch,
//     control,
//     setValue,
//   } = useForm({
//     resolver,
//     defaultValues: {
//       splitType: "fiat",
//       revenueIds: [],
//       agencyId: 0,
//       settlementAccounts: [
//         {
//           accountId: "",
//           bankId: "",
//           bankCode: "",
//           accountNumber: "",
//           currencyId: "",
//           currencyCode: "",
//           thirdPartyShare: "",
//           isAccountNumberValidated: false,
//           validatedAccountName: "",
//         },
//       ],
//     },
//   });