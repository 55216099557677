import { instanceActionTypes } from "../../services/customer";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {
  data: [],
  loadingData: false,
  dataError: null,
};

const customerTabReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(instanceActionTypes.GET_CUSTOMERS):
      return {
        ...state,
        data: [],
        dataError: null,
        loadingData: true,
      };
    case SUCCESS(instanceActionTypes.GET_CUSTOMERS):
      console.log("SUCCESS:" + JSON.stringify(actions.payload));
      return {
        ...state,
        loadingData: false,
        data: actions.payload.data,
      };

    case FAILURE(instanceActionTypes.GET_CUSTOMERS):
      return {
        ...state,
        dataError: actions.payload,
        loadingData: false,
      };

    default:
      return { ...state };
  }
};

export default customerTabReducer;
