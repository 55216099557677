import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Invoice.scss";
import moment from "moment";
import InvoiceTable from "./InvoiceTable";
import { useQuery } from "react-query";
import {
  fetchCurrency,
  getCustomers,
  getLocations,
  getRevenue,
  getStatus,
  searchInvoice,
} from "services/invoice";
import { getUserDetails } from "apiServices/user.service";
import { set } from "lodash";
import { AppButton } from "components/Button/Button";
import { ButtonOutline } from "components/Button/ButtonOutlined";
import EmptyState from "components/emptyState/EmptyState";
import Loader, { LoadingTableContent } from "components/loader/Loader";
import { Table, Button } from "vgg-ui-react/dist/components/Core";

const Invoices = () => {
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [values, setValues] = useState("");
  const userDetails = getUserDetails();
  const [loading, setLoading] = useState(false);
  const [invoiceSearchLoading, setInvoiceSearchLoading] = useState(false);
  const [advanceSearchloading, setAdvanceSearchLoading] = useState(false);
  const defaultValue = { value: 0, label: "Select " };
  const [advanceSearchValues, setAdvancedSearchValues] = useState({
    customerId: 0,
    revenueId: 0,
    locationId: 0,
    status: -1,
    currencyId: 0,
    invoiceNumber: "",
    startDate: moment().subtract(30, "days").format("yy-MM-DD"),
    endDate: moment().format("yy-MM-DD"),
  });

  //   Math.ceil(total_items/limit);
  const [filters, setFilters] = useState({
    pageSize: 10,
    from: 0,
    parameter: {
      //   agencyId: userDetails?.organizationId,
      //   startDate: moment().subtract(30, "days").format("yy-MM-DD"),
      //   endDate: moment().format("yy-MM-DD"),
      //   dateType: 1,
      //   locationId: 0,
      //   invoiceNumber: "",
      //   name: "",
      //   providerId: 0,
      //   revenueId: 0,
      //   status: -1,
      //   currencyId: 0,

      locationId: 0,

      invoiceNumber: "",

      name: "",

      agencyId: userDetails?.organizationId,

      startDate: "2022-03-01T00:00:00.000Z",

      endDate: "2022-04-07T23:00:00.000Z",

      dateType: 1,

      providerId: 188,

      revenueId: 98,

      status: "-1",

      currencyId: 566,
    },
  });
  const handleInvoiceSearch = (event) => {
    event.preventDefault();
    setInvoiceSearchLoading(true);
    setFilters({
      ...filters,
      parameter: {
        ...filters.parameter,
        invoiceNumber: values,
      },
    });
  };
  const handleNameSearch = (event) => {
    event.preventDefault();
    setLoading(true);
    setFilters({
      ...filters,
      parameter: { ...filters.parameter, name: values },
    });
  };
  const handleAdvanceSearch = (event) => {
    event.preventDefault();
    setAdvanceSearchLoading(true);
    setFilters({
      ...filters,
      parameter: {
        ...filters.parameter,
        invoiceNumber: advanceSearchValues.invoiceNumber,
        revenueId: advanceSearchValues.revenueId,
        providerId: advanceSearchValues.customerId,
        locationId: advanceSearchValues.locationId,
        status: advanceSearchValues.status,
        startDate: advanceSearchValues.startDate,
        endDate: advanceSearchValues.endDate,
      },
    });
  };

  const onPageClick = (evt) => {
    setFilters((prev) => ({ ...prev, from: evt }));
  };

  const { isLoading, isError, data, error } = useQuery(
    [
      {
        filters,
      },
    ],
    () => searchInvoice(filters),
    {
      enabled: true,
      retry: false,
      staleTime:0,
      notifyOnChangeProps: ["data"],
      onSuccess: (res) => {
        // console.log('res', res)
      },
      onError: (err) => {
        // console.log('err', err)
      },
    }
  );

  const {
    data: currencies,
    error: currenciesError,
    isLoading: currenciesLoading,
  } = useQuery(["getCurrency", {}], () => fetchCurrency(), {
    enabled: true,
    retry: false,
    staleTime:0
  });
  const {
    data: locations,
    error: locationsError,
    isLoading: locationsLoading,
  } = useQuery(
    ["getLocations", userDetails?.organizationId],
    () => getLocations(userDetails?.organizationId),
    {
      enabled: true,
      retry: false,
      staleTime:0
    }
  );
  const {
    data: revenues,
    error: revenuesError,
    isLoading: revenuesLoading,
  } = useQuery(
    ["getRevenue", userDetails?.organizationId],
    () => getRevenue(userDetails?.organizationId),
    {
      enabled: true,
      retry: false,
      staleTime:0
    }
  );
  const {
    data: customers,
    error: customersError,
    isLoading: customersLoading,
  } = useQuery(
    ["getCustomers", userDetails?.organizationId],
    () => getCustomers(userDetails?.organizationId),
    {
      enabled: true,
      retry: false,
      staleTime:0
    }
  );
  const {
    data: status,
    error: statusError,
    isLoading: statusLoading,
  } = useQuery(["getStatus", {}], () => getStatus(), {
    enabled: true,
    retry: false,
    staleTime:0
  });

  return (
    <div className="invoice_container">
      <div className="row  align-items-center  justify-content-between">  
      <div className="col-lg-3 mb-4 mb-md-0">
        
        <div className="search-container">
          <input
            type="search "
            placeholder="search"
            className="form-control "
            onChange={(e) => setValues(e.target.value)}
          />
          <i className="ri-search-line"> </i>
      

        </div>
        </div>  
        <div className="col-lg-6  d-flex  flex-md-row  flex-nowrap my-lg-0 my-4  justify-content-start justify-content-lg-end">
        
         
            <div className="">
              <AppButton
                label="Search By Invoices"
                color="blue"
                isLoading={invoiceSearchLoading && isLoading}
                onClick={handleInvoiceSearch}
              />
            </div>
            <div className="">
              <AppButton
                label="Search By CustomerName"
                color="blue"
                size="sm"
                isLoading={loading && isLoading}
                onClick={handleNameSearch}
              />
            </div>

            <div className=" ">
              <AppButton
                label="Advance Search"
                color="blue"
                size="sm"
                 
                onClick={() => setShowAdvanceSearch(!showAdvanceSearch)}
              />
             

            {/* <Button
          label=""
          color=""
          size="md"
          className="btn btn-outline-primary"
          // onClick={() => history.push("configure")}
        /> */}
          </div>
        </div>
      </div>

      <div className="form mt-5  ">
        {showAdvanceSearch && (
          <div
            className="row align-items-center mt-3"
            style={{ marginBottom: "2.5rem" }}
          >
            <div className="col-md-4 form-group mb-2">
              <label htmlFor=""> Invoice Number</label>
              <input type="text" className="form-control " />
            </div>
            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Revenue</label>
              <Select
                placeholder="Select"
                options={revenues?.data?.map((org) => {
                  return {
                    value: org.id,
                    label: org.name,
                  };
                })}
                onChange={(newValue) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    revenueId: parseInt(newValue.value),
                  })
                }
                isLoading={revenuesLoading}
                defaultValue={defaultValue}
                // styles={customStyles}
              />
            </div>

            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Customer Name</label>
              <Select
                placeholder="Select"
                options={customers?.data?.map((org) => {
                  return {
                    value: org.id,
                    label: org.customerName,
                  };
                })}
                onChange={(newValue) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    customerId: parseInt(newValue.value),
                  })
                }
                isLoading={customersLoading}
                defaultValue={defaultValue}
                // styles={customStyles}
              />
            </div>

            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Locations</label>
              <Select
                placeholder="Select"
                options={locations?.data?.map((org) => {
                  return {
                    value: org.id,
                    label: org.code,
                  };
                })}
                onChange={(newValue) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    locationId: parseInt(newValue.value),
                  })
                }
                isLoading={locationsLoading}
                loadingMessage="....loading"
                defaultValue={defaultValue}
                // styles={customStyles}
              />
            </div>
            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Status</label>
              <Select
                placeholder="Select"
                options={status?.map((org) => {
                  return {
                    value: org.id,
                    label: org.name,
                  };
                })}
                onChange={(newValue) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    status: parseInt(newValue.value),
                  })
                }
                // isLoading={statusLoading}
                defaultValue={defaultValue}
                // styles={customStyles}
              />
            </div>

            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Start Date</label>
              <input
                type="date"
                name=""
                id=""
                className="form-control"
                value={advanceSearchValues.startDate}
                onChange={(e) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    startDate: e.target.value,
                  })
                }
                placeholder="Start Date"
              />
            </div>
            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">End Date</label>
              <input
                type="date"
                name=""
                id=""
                value={advanceSearchValues.endDate}
                onChange={(e) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    endDate: e.target.value,
                  })
                }
                placeholder="End Date"
                className="form-control"
              />
            </div>
            <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Currency</label>
              <Select
                placeholder="Select"
                options={currencies?.data?.map((org) => {
                  return {
                    value: org.id,
                    label: org.name,
                  };
                })}
                onChange={(newValue) =>
                  setAdvancedSearchValues({
                    ...advanceSearchValues,
                    currencyId: parseInt(newValue.value),
                  })
                }
                isLoading={currenciesLoading}
                defaultValue={defaultValue}
                // styles={customStyles}
              />
            </div>
            {/* <div className="col-md-4 form-group mb-2">
              <label htmlFor="">Sub Revenue</label>
              <input type="text" className="form-control" />
            </div> */}

            <div className="col-md-4 form-group">
              <AppButton 
                style={{ marginTop: "35px" }}
                type="button"
                onClick={handleAdvanceSearch}
                label="Search"
                color="blue"
                isLoading={isLoading && advanceSearchloading}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-5">
        {isLoading && (
          <Table variant="">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Status</th>
                <th>Date</th>
                <th>Customer</th>
                <th>Name</th>
                <th>Revenue</th>
                <th>Location</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={8} />
            </tbody>
          </Table>
        )}

        {data?.results?.length === 0 && (
          <div className="mt-8">
            <EmptyState description={data.message} />
          </div>
        )}
        {/* {isError && <EmptyState />} */}
        <InvoiceTable data={data} filters={filters} onPageClick={onPageClick} />
      </div>
    </div>
  );
};

export default Invoices;
