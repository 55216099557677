import { Get, Patch, Post, Put } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
  GET_INSTANCES: "[WALLET - INSTANCES] GET_INSTANCES",
  GET_WALLET_HISTORY: "[WALLET - INSTANCES] GET_WALLET_HISTORY",
  GET_ACCOUNTS: "[WALLET - INSTANCES] GET_ACCOUNTS",
  CREATE_INSTANCE: "[WALLET - INSTANCES] CREATE_INSTANCE",
  RESET_CREATE_INSTANCE: "[WALLET - INSTANCES] RESET_CREATE_INSTANCE",
  EDIT_INSTANCE: "[WALLET - INSTANCES] EDIT_INSTANCE",
  TOGGLE_STATUS: "[WALLET - INSTANCES] TOGGLE_STATUS",
  GET_WALLET_SUMMARY: "[WALLET - INSTANCES] GET_WALLET_SUMMARY",
  GET_CHART_DATA: "[WALLET - INSTANCES] GET_CHART_DATA",
};

export const LoadWalletInstances = createAsyncAction(
  instanceActionTypes.GET_INSTANCES,
  async ({ pageNo, pageSize, searchBy }) => {
    return await Get(
      `v1/settings/instances${pageNo ? "?PageNumber=" + pageNo : ""}${
        pageSize ? "&MaxItem=" + pageSize : ""
      }${searchBy ? "&SearchBy=" + searchBy : ""}`
    );
  }
);

export const LoadWalletHistory = createAsyncAction(
  instanceActionTypes.GET_WALLET_HISTORY,
  async ({ instanceId }) => {
    return await Get("v1/walletHistory.json");
  }
);
export const ConfigureWalletInstance = createAsyncAction(
  instanceActionTypes.CREATE_INSTANCE,
  async (data, instanceId) => {
    const customHeader = instanceId ? { InstanceId: instanceId } : {};

    return instanceId
      ? await Put(`v1/settings/updateinstance`, data, customHeader)
      : await Post(`v1/settings/addinstance`, data, customHeader);
  }
);

export const ResetConfiguration = createAsyncAction(
  instanceActionTypes.RESET_CREATE_INSTANCE,
  async () => {}
);

export const editWalletInstance = createAsyncAction(
  instanceActionTypes.EDIT_INSTANCE,
  async (data) => {
    return await Patch("v1/account/instances", data);
  }
);
export const toggleStatus = createAsyncAction(
  instanceActionTypes.TOGGLE_STATUS,
  async ({ activate, instanceId }) => {
    return await Post(
      `v1/settings/status/instance?status=${activate}`,
      {},
      { InstanceId: instanceId }
    );
  }
);
export const LoadAccounts = createAsyncAction(
  instanceActionTypes.GET_ACCOUNTS,
  async ({ instanceId }) => {
    return await Get("v1/account/getaccounts", { InstanceId: instanceId });
  }
);
export const LoadWalletSummary = createAsyncAction(
  instanceActionTypes.GET_WALLET_SUMMARY,
  async ({ instanceId }) => {
    return await Get("v1/settings/instance/detail", { InstanceId: instanceId });
  }
);
export const LoadChartData = createAsyncAction(
  instanceActionTypes.GET_CHART_DATA,
  async () => {
    return await Get("chartData.json");
  }
);
