import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  PaginationWrapper,
  Pagination,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";
import TableActionDropdown from "components/tableActionDropdown/tableActionDropdown";
import EmptyState from "components/emptyState/EmptyState";
import Switch from "components/switch/Switch";
import SettlementSplitDetails from "../../pages/SettlementSplit/SettlementDetails/SettlementSplitDetails";
import NewSettlementSplit from "./NewSettlementSplit";
import moment from "moment";

const SettlementSplitTable = ({ data, filters, onPageClick }) => {
  const history = useHistory();
  const [dataToSwitch, setDataToSwitch] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [addNew, setAddNew] = useState(false);

  const onViewDetails = (index) => {
    console.log("Ind", index);

    setViewDetails(!viewDetails);
    setCurrentIndex(index);
  };
  
  const addNewSplits = (event, rowData) => {
    console.log(rowData);
    setAddNew(!addNew);
  };

  const tableActions = [
    {
      id: 1,
      method: onViewDetails,
      name: "View Details",
    },
  ];

  const onEditIconClick = (event, rowData) => {
    // const { id } = params;
    // history.push({
    //   pathname: ConfigureUpdate(id),
    //   state: { data: history?.location?.state?.data },
    // });
  };

  return (
    <div className="w-100">
      {data?.results && data?.results?.length ? (
        <>
          <Table variant="unbounded">
            <thead>
              <tr className="">
                {/* <th>Service Type</th> */}
                <th>Revenue Name</th>
                <th>Split Type</th>
                <th>Organization</th>
                <th>date</th>
                <th className="align-middle text-center">Actions</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: "none" }}>
              {data?.results?.map((app, idx) => (
                <tr key={idx}>
                  <td> {app.revenueName}</td>
                  <td> {app?.splitTypeName}</td>
                  <td>{app?.organization}</td>
                  <td>{moment(app.dateCreated).format("DD-MM-YYYY")}</td>

                  <td className="align-middle">
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="me-4"
                        style={{ cursor: "pointer" }}
                        onClick={()=> onViewDetails(idx)}
                      >
                        <i className="ri-pencil-line"></i>
                      </span>
                      {/* <span
                        className="me-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => onViewDetails(idx)}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </span> */}
                      {/* <TableActionDropdown
                          data={app}
                          actions={tableActions}
                          horizontal={false}
                        /> */}
                    </div>
                  </td>
                </tr>
              ))}{" "}
            </tbody>
          </Table>
          <div>
            <PaginationWrapper>
              <PaginationIndicator
                currentPage={filters.from + 1}
                totalPages={Math.ceil(data.totalCount / filters.pageSize)}
              />
              <div className="pagination-container">
                <Pagination
                  className="pagination-bar"
                  currentPage={filters.from + 1}
                  totalCount={data?.totalCount}
                  pageSize={filters?.pageSize}
                  onPageChange={(page) => onPageClick(page)}
                />
              </div>
            </PaginationWrapper>
          </div>
        </>
      ) : null}
      <SettlementSplitDetails
        show={viewDetails}
        size="lg"
        toggleModal={() => setViewDetails(false)}
        onSubmit={() => {}}
        splitDetails={data?.results && data?.results[currentIndex]}
        // processing={switchingStatus}
      />

      {/* <NewSettlementSplit
        show={addNew}
        size="lg"
        toggleModal={addNewSplits}
        // onSubmit={() => {}}
        // processing={switchingStatus}
      /> */}
    </div>
  );
};

export default SettlementSplitTable;
