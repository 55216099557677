import React from "react";




export const appHelpers = {

randomArrayOfNumbers: function (length, multiplier) { 
    return Array.from({ length: length }, () =>
      Math.floor(Math.random() * multiplier)
    );
  },

  findAllByKey: function (obj, keyToFind) {
    if (obj) {
      return Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key === keyToFind
            ? acc.concat(value)
            : typeof value === "object"
            ? acc.concat(appHelpers.findAllByKey(value, keyToFind))
            : acc,
        []
      );
    }
  },

  
  numberWithCommas: (x, precision) => {
    if(x){
      if (x.length < 2) {
        return x;
      }
      if (precision) {
        x = x.toFixed(precision);
      } else {
        x = x.toFixed(2);
      }
  
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
   return x;
  },
}