import { setUserDetails } from "apiServices/user.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { Post } from "../apiServices/apiHandler.service";

export const instanceActionTypes = {
  LOGIN_INSTANCES: "[LOGIN - INSTANCES]",
};
export const Login = async (data) => {
  

 return  Post(`/Account/Login`, data);
  // console.log(res.data);

  // setUserDetails(res.data);
  // return res
};

export const validateClientService = (payload) => {
  return Post(`OnBoarding/ValidateClientBDA`, payload);
};
export const onboardService = (payload) => {
  return Post(`OnBoarding/OnBoardClientBDA`, payload);
};
