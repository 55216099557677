export const getCurrencyValue = (currencyCode, value) => {
  if (value || value === 0) {
    switch (currencyCode.toLowerCase()) {
      case "ngn":
        return (
          <span>
            &#8358;{" "}
            {typeof value === "number" ? value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : typeof value === "string" && +value ? (+value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : value}
          </span>
        );

      default:
        return "--";
    }
  } else {
    return "--";
  }
};
