import React, { useState, useEffect, } from 'react'
import { useQuery } from 'react-query'
import swal from "@sweetalert/with-react";
import EmptyState from "components/emptyState/EmptyState";
import OverviewCard from "./OverViewCard";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
// import Button from "vgg-ui-react/dist/components/Core/Button";
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Badge,
    Container,
} from "reactstrap";
import { fetchCurrency } from "services/invoice";
import BarChartCard from "./BarChartCard";
import DoughnutChartCard from "./DoughnutChartCard";
import { getAdminDashboard } from "services/dashboard";
import { AxiosError } from 'axios'
import moment from "moment";
import { appHelpers } from "assets/helpers/appHelpers";
import { useDispatch } from "react-redux";
import FilterDropdown from './FilterDropDown/FilterDropDown';
import Select from "react-select";


const Dashboard = () => {
    const [pieChartData, setPieChartData] = useState({});
    const [barChartData, setBarChartData] = useState({});
    const [dashboardData, setDashboardData] = useState({});
    const [dateRange, setDateRange] = useState(null);
    const [remount, setRemount] = useState(false);
    const [setCurrency, selectedCurrency] = useState("");
    const defaultValue = { value: 0, label: "Naira" };
    const [currencyValues, setCurrencyValues] = useState({
        currencyId: 566,
    });
    const [filters, setFilters] = useState({
            startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
            endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
            currencyId: 566,
    })
    let startDate=dateRange ? dateRange?.start?.format("YYYY-MM-DD") : moment().startOf('month').format('YYYY-MM-DD')
   let  endDate = dateRange ? dateRange?.end?.format("YYYY-MM-DD") :  moment().format("YYYY-MM-DD")
//  let startDate = moment().startOf('month').format('YYYY-MM-DD')
//  let endDate = moment().format("YYYY-MM-DD")
    const dispatch = useDispatch();

    const renderContent = range => {
        return <>{!range ? "Monthly" : `${range?.start?.format("LL")} - ${range?.end?.format("LL")}`}</>;
    };

    const onCurrencySelected = cur => {
        selectedCurrency(cur);
    };

    const {
        data: currencies,
        error: currenciesError,
        isLoading: currenciesLoading,
    } = useQuery(["getCurrency", {}], () => fetchCurrency(), {
        enabled: true,
        retry: false,
        staleTime: 0
    });

    const onSelect = value => {
        const start = value.start;
        const end = value.end;
        const dateCount = end.diff(start, "days") + 1;
        if (dateCount > 30) {
            swal({
                text: "Kindly select range to be at most 30 days.",
                icon: "info",
                button: "Okay",
            });
        } else {
            setDateRange(value);
        }
    };
   
    const {
        isLoading,
        isError,
        data: response,
        error,
        refetch,
    } = useQuery(
        [startDate, endDate,
        filters.currencyId
        ],
        () => getAdminDashboard(startDate,endDate,
            filters.currencyId
        ),
        {
            enabled: true,
            retry: false,
            staleTime: 0,
            onSuccess: (response) => {
                console.log(response, "what now")
                setDashboardData(response?.data)
            },
            onError: (err) => { },
        }
    )
    
    // useEffect(() => {
    //     dispatch(
    //       getAdminDashboard({
    //         startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
    //         endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
    //       })
    //     );
    //     if (remount) {
    //       setRemount(false);
    //     }
    //   }, [dateRange]);

    // useEffect(() => {
    //     dispatch(getAdminDashboard({ walletId: "NGN" }));    
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, []);
    console.log(currencies, "cur");
    console.log(filters, "filet");

    const { top5Invoice, top5Payment, nairaInvoice, dollarInvoice, nairaPayment, dollarPayment } = dashboardData
    return (
        <div>
            <Row style={{ marginBottom: "30px", marginLeft:"23px"}}>
                <Col xs="12" sm="6" md="4" lg="2">
                    <Select
                        placeholder="Select"
                        options={currencies?.data?.map((org) => {
                            return {
                                value: org.id,
                                label: org.name,
                            };
                        })}
                        onChange={(newValue) =>
                            setFilters({
                                ...filters,
                                currencyId: parseInt(newValue.value),
                            })
                        }
                        // isLoading={currenciesLoading}
                        defaultValue={defaultValue}
                    />
                </Col>
                <Col>
                    <FilterDropdown value={renderContent(dateRange)} showSelectedValue>
                        {!remount && <DateRangePicker numberOfCalendars={2} value={dateRange} onSelect={onSelect} className="transaction-datepicker" />}
                        {dateRange && (
                            <div className="py-2 px-3">
                                <Button
                                    label="Clear Selection"
                                    background="blue"
                                    className="w-100"
                                    onClick={() => {
                                        setRemount(true);
                                        setDateRange(null);
                                    }}
                                />
                            </div>
                        )}
                    </FilterDropdown>
                </Col>
            </Row>
            <Row style={{ marginBottom: "30px", marginLeft: "23px" }}>
                <Col md="6" lg="3">
                    <OverviewCard
                        label={`Invoices This Month`}
                        amount={appHelpers.numberWithCommas(dashboardData?.nairaInvoice ? nairaInvoice?.itemTotal : "0")}
                        // currency="naira"
                        count={dashboardData?.nairaInvoice ? nairaInvoice?.itemCount : "0"}
                        borderColor="#40D999"
                        visible={isLoading}
                    />
                </Col>
                <Col md="6" lg="3">
                    <OverviewCard
                        label={`Payments This Month`}
                        amount={appHelpers.numberWithCommas(dashboardData?.nairaPayment ? nairaPayment?.itemTotal : "0")}
                        // currency="naira"
                        count={dashboardData?.nairaPayment ? nairaPayment?.itemCount : "0"}
                        borderColor="#28baf0"
                        visible={isLoading}
                    />
                </Col>
            </Row>

            <Row style={{ marginBottom: "30px", marginLeft: "20px" }}>
                <Col lg="11" md="12">
                    <BarChartCard
                        title="Total number of invoices issued and payments made"
                        barData={dashboardData && !isLoading ? dashboardData : ""}
                        visible={isLoading}
                    />
                </Col>

            </Row>
            <Row style={{ marginLeft: "17px", marginRight: "10px" }}>
                {/* Doughnut chart of top 5 revenue lines */}
                <Col lg="6" md="12">
                    <DoughnutChartCard
                        title="Top 5 Revenue Lines (Invoices)"
                        doughnutData={!isLoading ? top5Invoice : ""}
                        pieChartData={!isLoading ? dashboardData?.top5Invoice : ""}
                        visible={isLoading}
                    />

                </Col>
                <Col lg="6" md="12">
                    <DoughnutChartCard
                        title="Top 5 Revenue Lines (Payments)"
                        doughnutData={!isLoading ? top5Payment : ""}
                        pieChartData={!isLoading ? dashboardData?.top5Payment : ""}
                        visible={isLoading}
                    />
                </Col>
            </Row>
        </div>
    );
};


export default Dashboard;
