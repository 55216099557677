import React, { useEffect, useState } from "react";
import {
  Table,
  PaginationWrapper,
  Pagination,
  Button,
  Select,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";
// import Select from "react-select";
import { useQuery } from "react-query";
import EmptyState from "components/emptyState/EmptyState";
import "./settlementAccount.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingTableContent } from "components/loader/Loader";
import _debounce from "lodash.debounce";
import {
  LoadSettlement,
  getCurrency,
  getRevenue,
} from "services/settlementAccount";
import { AppButton } from "components/Button/Button";
import { getUserDetails } from "apiServices/user.service";
import ConfigureAccount from "../../components/settlementAccount/configureAccount";
import { errorMessage } from "redux/actions/notification";

const SettlementAccount = () => {
  const [showConfiguration, setConfiguration] = useState(false);
  const [configurationValues, setConfigurationValues] = useState({
    revenueId: 0,
    currencyId: 0,
  });
  const [settlementSearch, setSettlementSearch] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = getUserDetails();
  const [addNew, setAddNew] = useState(false);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const defaultValue = { value: 0, label: "Select " };
  const [filterType, setFilterType] = useState("Filter");
  // const [isError, setIsError] = useState(false);
  const [filters, setFilters] = useState({
    from: 1,
    pageSize: 10,
    parameter: {
      searchQuery: "",
      agencyId: userDetails?.organizationId,
      // agencyId: 20,
    },
  });

  const onPageClick = (evt) => {
    setFilters((prev) => ({ ...prev, from: evt }));
  };

  const handleSearch = _debounce((event) => {
    dispatch(
      LoadSettlement({
        settlement: event.target.value,
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );
    // cb(true);
  }, 1000);

  const { isLoading, data, error } = useQuery(
    [
      {
        filters,
      },
    ],
    () => LoadSettlement(filters),
    {
      enabled: true,
      retry: false,
      staleTime: 0,
      notifyOnChangeProps: ["data"],
      onSuccess: (res) => {
        // console.log('res', res)
      },
      onError: (err) => {
        // console.log('err', err)
        setIsError(true);
        dispatch(
          errorMessage(err?.data?.message || "An error occured")
        );
        setIsError(false);
      },
    }
  );
  const {
    data: currencies,
    error: currenciesError,
    isLoading: currenciesLoading,
  } = useQuery(["getCurrency", {}], () => getCurrency(), {
    enabled: true,
    retry: false,
    staleTime: 0,
  });

  const {
    data: revenues,
    error: revenuesError,
    isLoading: revenuesLoading,
  } = useQuery(
    ["getRevenue", userDetails?.organizationId],
    () => getRevenue(userDetails?.organizationId),
    {
      enabled: true,
      retry: false,
      staleTime: 0,
    }
  );

  const addNewAccount = (event, rowData) => {
    console.log(rowData);
    setAddNew(!addNew);
  };
  // useEffect(() => {
  //     dispatch(
  //         LoadSettlement({
  //             settlement: "",
  //             pageNo: paging.pageNumber,
  //             pageSize: paging.pageSize,
  //         })
  //     );
  // }, [paging]);
  // console.log(loadingData, "data");

  return (
    <div className="settlement-container">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="search-container">
          <input
            type="search"
            placeholder="search"
            className="form-control"
            onChange={handleSearch}
          />
          <i className="ri-search-line"></i>
        </div>
        <div className="d-flex align-items-center">
          <AppButton
            label="Configure"
            color="blue"
            // onClick={addNewAccount}
            onClick={() =>
              history.push("/services/settings/configure-settlement-account")
            }
            // onClick={"/services/configure-settlement-account"}
          />
        </div>
      </div>

      <div className="mt-5">
        {isLoading ? (
          <Table variant="">
            <thead>
              <tr>
                <th width="10%">Bank Name</th>
                <th width="30%">Account Number</th>
                <th width="15%">Account Name</th>
                <th width="15%">Account Type</th>
                <th width="15%">Actions</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          </Table>
        ) : (
          <>
            {data?.results && data?.results?.length ? (
              <>
                <Table variant="unBounded">
                  <thead>
                    <tr>
                      <th width="10%">Organization</th>
                      <th width="10%">Bank Name</th>
                      <th width="30%">Account Number</th>
                      <th width="15%">Account Name</th>
                      <th width="15%">Account Type</th>
                      <th width="15%">Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "none" }}>
                    {data?.results?.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.organization}</td>
                        <td>{item.bankName}</td>
                        <td>{item.accountNumber}</td>
                        <td>{item.accountName}</td>
                        <td>{item.accountTypeName}</td>
                        <td className="align-middle">
                          <div className="d-flex align-items-center justify-content-center">
                            <span
                              className="me-4"
                              style={{ cursor: "pointer" }}
                              //   onClick={ addNewAccount}
                            >
                              <i className="ri-pencil-line"></i>
                            </span>

                            {/* <span
                              className="me-4"
                              style={{ cursor: "pointer" }}
                              //   onClick={onViewDetails}
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </span> */}
                          </div>
                        </td>{" "}
                      </tr>
                    ))}{" "}
                  </tbody>
                </Table>
                <div>
                  <PaginationWrapper>
                    <PaginationIndicator
                      currentPage={paging?.pageNumber || 1}
                      totalPages={paging?.pages || 1}
                    />
                    <div className="pagination-container">
                      <Pagination
                        className="pagination-bar"
                        currentPage={paging?.pageNumber}
                        totalCount={data?.totalCount || 1}
                        pageSize={paging?.pageSize}
                        onPageChange={(page) => onPageClick(page)}
                      />
                    </div>
                  </PaginationWrapper>
                </div>
              </>
            ) : (
              <EmptyState />
            )}
          </>
        )}
      </div>
      {/* <ConfigureAccount
                show={addNew}
                size="lg"
                toggleModal={addNewAccount}
            /> */}
    </div>
  );
};

export default SettlementAccount;
