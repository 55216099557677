import { setUserDetails } from "apiServices/user.service";
import { values } from "lodash";
import { Get, Post } from "../apiServices/apiHandler.service";

export const instanceActionTypes = {
  LOGIN_INSTANCES: "[LOGIN - INSTANCES]",
};
export const getRevenue = async (organizationId) => {
  return await (await Get(`/Revenue/GetRevenuesBDA?OrganizationId=${organizationId}`)).data;
};

export const getCustomers = async (organizationId) => {
  return (await Get(
    `/Customer/GetCustomersBDA?OrganizationId=${organizationId}&includeChildCustomers=false`)).data;
};
export const getLocations = async (organizationId) => {
  return await (await Get(`/Location/GetLocationsBDA?OrganizationId=${organizationId}`)).data;
};

export const fetchCurrency = async () => {
  return await (await Get(`/Currency/GetCurrenciesBDA`)).data;
};

export const getStatus = () => {
  return [
    {
      name: "Paid",
      id: "1",
    },
    {
      name: "partlyPaid",
      id: "2",
    },
    {
      name: "unPaid",
      id: "3",
    },
    {
      name: "Voided",
      id: "4",
    },
  ];
};
export const searchInvoice = async (payload) => {
    return await (await Post(`Invoice/InvoiceSearchBDA`, payload)).data;
  }
