import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "vgg-ui-react/dist/components/Core/Typography";

export const Header = () => {
  const history = useHistory()
  return (
    <div className="service-details px-4" onClick={() => history.goBack() }>
    <section className="head-section">
      <span className="head-section__icon ">
        {/* <i className="ri-bank-card-line"></i>
         */}
          <i className="fa fa-angle-left" aria-hidden="true"></i>
           
      
      </span >
      <div className="head-section__title">
     
          
        <p className="pg-title">Invoice as a Service</p>
        {/* <p className="pg-subtitle">
        Services or API
        </p> */}
          
        
         
      </div>
    </section>
    </div>
  );
};
