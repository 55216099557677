import { isLoggedIn } from "apiServices/user.service";
import CustomerInfoTab from "components/customerTab/customer";
import Dashboard from "components/Dashboard/DashBoard";
import EmptyState from "components/emptyState/EmptyState";
import Invoices from "components/Invoices/Invoices";
import LocationInfoTab from "components/locationInfoTab/LocationInfo";
import RevenueInfoTab from "components/revenueTab/revenue";
import ConfigureAccount from "components/settlementAccount/configureAccount";
import NewSettlementSplit from "components/SettlementSplit/NewSettlementSplit";
import AboutService from "pages/About/About";
import SettingsPage from "pages/Settings/Settings";
import SettlementAccount from "pages/SettlementAccount/settlementAccount";
import SettlementSplit from "pages/SettlementSplit/SettlementSplit";
// import Dashboard from "components/Dashboard/Dashboard";
import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const ServiceDetailsRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        {" "}
        {<Redirect to="/services/dashboard" />}
      </Route>
      <PrivateRoute
        exact
        path={`/services/dashboard`}
        component={Dashboard}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/invoice`}
        component={Invoices}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/about-invoice-service`}
        component={AboutService}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/customer`}
        component={CustomerInfoTab}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/settings/location`}
        component={LocationInfoTab}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/service-type`}
        component={RevenueInfoTab}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/settings/settlement-account`}
        component={SettlementAccount}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />

      <PrivateRoute
        exact
        path={`${path}/settings/settlememnt-splits`}
        component={SettlementSplit}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/settings/configure-settlemement-split`}
        component={NewSettlementSplit}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />

      <PrivateRoute
        exact
        path={`${path}/settings/configure-settlement-account`}
        component={ConfigureAccount}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
      <PrivateRoute
        exact
        path={`${path}/settings`}
        component={SettingsPage}
        condition={localStorage.getItem("user_det")}
        redirectTo={"/onboard"}
      />
    </Switch>
  );
};

export default ServiceDetailsRoutes;
