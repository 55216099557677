import React, { useEffect, useState } from "react";
import {
  Table,
  PaginationWrapper,
  Pagination,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
// eslint-disable-next-line
import { LoadCustomer } from "services/customer";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import "./customer.scss";
import { useHistory } from "react-router-dom";

const statuses = [
    { label: "Enabled", value: true },
    { label: "Disabled", value: false },
  ];
  
const CustomerInfoTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loadingData,
    data,
    switchingStatus,
    switchStatusSuccess,
    switchStatusError,
  } = useSelector(({ customerTab }) => customerTab);
  const [DataToSwitch, setDataToSwitch] = useState(null);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });

  useEffect(() => {
    dispatch(
      LoadCustomer({
        location: "",
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  useEffect(() => {
    if (!switchingStatus && switchStatusSuccess) {
      setDataToSwitch(null);
      dispatch(
        LoadCustomer({
          location: "",
          pageNo: paging.pageNumber,
          pageSize: paging.pageSize,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchingStatus, switchStatusSuccess, switchStatusError]);

  const onPageClick = (evt) => {
    setPaging((prev) => ({ ...prev, pageNumber: evt }));
  };

  const handleSearch = _debounce((event) => {
    dispatch(
      LoadCustomer({
        location: event.target.value,
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );
    // cb(true);
  }, 1000);

  return (
    <div className="customer-tab-container">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="search-container">
          <input
            type="search"
            placeholder="search"
            className="form-control"
            onChange={handleSearch}
          />
          <i className="ri-search-line"></i>
        </div>
      </div>
      {loadingData ? (
        <Table variant="">
          <thead>
            <tr>
              <th width="10%">Code</th>
              <th width="30%">Customer</th>
              <th width="15%">Email</th>
              <th width="15%">Phone No</th>
              <th width="15%">Self Service</th>
              <th width="15%">Status</th>
            </tr>
          </thead>
          <tbody style={{ borderTop: "none" }}>
            <LoadingTableContent colSpan={6} />
          </tbody>
        </Table>
      ) : (
        <>
          {data.results && data.results.length ? (
            <>
              <Table variant="unBounded">
                <thead>
                  <tr>
                    <th width="10%">Code</th>
                    <th width="30%">Customer</th>
                    <th width="15%">Email</th>
                    <th width="15%">Phone No</th>
                    <th width="15%">Self Service</th>
                    <th width="15%">Status</th>
                  </tr>
                </thead>
                <tbody style={{ borderTop: "none" }}>
                  {data.results.map((app, idx) => (
                    <tr key={app.customerAIN}>
                      <td>{app.customerAIN}</td>
                      <td>{app.customerName}</td>
                      <td>{app.email}</td>
                      <td>{app.phone}</td>
                      {/* <td>{app.allowSelfService}</td> */}
                      <td>
                {app.allowSelfService === true
                  &&
                  <span style={{color: "green"}}><b>Yes</b></span>
                }
                {app.allowSelfService === false
                  &&
                <span style={{color: "red"}}><b>No</b></span>
                }
              </td>
              <td>
                {app.status === true
                  &&
                  <span style={{color: "green"}}><b>Active</b></span>
                }
                {app.status === false
                  &&
                <span style={{color: "red"}}><b>Not Active</b></span>
                }
              </td>
                    </tr>
                  ))}{" "}
                </tbody>
              </Table>
              <div>
                <PaginationWrapper>
                  <PaginationIndicator
                    currentPage={paging?.pageNumber || 1}
                    totalPages={paging?.pages || 1}
                  />
                  <div className="pagination-container">
                    <Pagination
                      className="pagination-bar"
                      currentPage={paging?.pageNumber}
                      totalCount={data?.totalCount || 1}
                      pageSize={paging?.pageSize}
                      onPageChange={(page) => onPageClick(page)}
                    />
                  </div>
                </PaginationWrapper>
              </div>
            </>
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </div>
  );
};

export default CustomerInfoTab;
