import { AppButton } from "components/Button/Button";
import { Spinner } from "components/Spinner/Spinner";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Table from "vgg-ui-react/dist/components/Core/Table";
import { revenueLineList } from "./revenue-line";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { createSettlementSplits } from "services/settlements";
import { errorMessage, successMessage } from "redux/actions/notification";
import { Scenario } from "./createSettlemementSplit/Scenario";
import SettlementAccount from "./createSettlemementSplit/settlementAccounts";
import { getUserDetails } from "apiServices/user.service";
import { useHistory } from "react-router-dom";
import { getSettlementAccountLocationRevenue } from "services/settlementAccount";

const NewSettlementSplit = ({ show, toggleModal, processing, size }) => {
  const validationSchema = Yup.object({
    splitType: Yup.number().required("This field is required"),
  });

  const resolver = useYupValidationResolver(validationSchema);
  const [revenueIds, setRevenueIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  let settlementAccountId = 0;
  // let agencyId = 20;
  let  agencyId = userDetails?.organizationId;

  const history = useHistory();

  const dispatch = useDispatch();
  const userDetails = getUserDetails();
  const [checkedRev, setChecked] = useState(false);
  const [allData, setAllData] = useState({
    splitType: "1",
    revenueIds: revenueIds,
    // agencyId: 20,
     agencyId: userDetails?.organizationId,
    settlementAccounts: [
      {
        // accountId: 0,
        bankId: "",
        bankCode: 0,
        accountNumber: "",
        currencyId: 0,
        currencyCode: "",
        thirdPartyShare: "",
        isAccountNumberValidated: true,
        validatedAccountName: "",
      },
    ],
  });
  const {
    data: settlementAccount,
    error: settlementAccountError,
    isLoading: settlementAccountLoading,
  } = useQuery(
    [
      "getSettlementAccountLocationRevenue",
      {
        // agencyId: 1,
        // settlementAccountId: 0
      },
    ],
    () => getSettlementAccountLocationRevenue(settlementAccountId, agencyId),
    {
      enabled: true,
      retry: false,
      staleTime: 0,
    }
  );
  // const { revenues, locations } = settlementAccount?.data
  const methods = useForm({
    mode: "onChange",
    defaultValues: allData,
  });
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState,
    setValue,
    watch,
    reset,
    values,
    setError,
    clearErrors,
  } = methods;

  const { mutateAsync: createNewSettlementSplit, isLoading } = useMutation(
    createSettlementSplits,
    {
      onSuccess: (response) => {
        console.log(response);
        dispatch(successMessage("configuration successful"));
        // toggleModal();
        reset();

        // navigate('/data-capture/aircraft-manifest')
      },
      onError: (error) => {
        dispatch(
          errorMessage(
            error?.data?.message || "Error while creating settlement split"
          )
        );
      },
    }
  );
  const val = getValues();

  const onSubmit = (data) => {
    console.log({ data });
    if (revenueIds.length < 1) {
      setError("revenueIds", "select atleast one revenue lines");

      return;
    }
    createNewSettlementSplit({
      ...val,
    });
  };

  useEffect(() => {
    clearErrors("revenueIds");
    setValue("revenueIds", revenueIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueIds]);

  const handleSelectAll = () => {
    const revenues = settlementAccount?.data?.revenues;
    setSelectAll(!selectAll);
    setRevenueIds(revenues?.map((item) => item.revenueId));
    if (selectAll) {
      setRevenueIds([]);
    }
  };
  console.log({ revenueIds });

  const handleChecked = (e) => {
    const { id, checked } = e.target;

    console.log(checked);

    if (id) {
      setRevenueIds([...revenueIds, parseInt(id)]);
    }
    if (!checked) {
      setRevenueIds(
        revenueIds.filter((revenueId) => revenueId !== parseInt(id))
      );
    }
  };

  return (
    <div className="w-100">
      <div>
        {/* <ModalHeader
          toggle={toggleModal}
          className="font-weight-bold title m-1 p-1 "
        ></ModalHeader> */}
        <div
        // style={{
        //   overflowY: "scroll",
        //   maxHeight: "700vh",
        //   padding: "0px 20px",
        // }}
        >
          <div className="d-flex justify-content-between align-items-center border-bottom py-2 w-100 mb-3">
            <h5 className="">New Split Settlememnt</h5>

            {/* <button className="btn btn-outline-primary rounded-pill"  onClick={() => history.push("/services/settlement-account")}>
              <span className="text-xs  " style={{ marginRight: "8px" }}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </span>
              Back
            </button> */}
          </div>

          <div className=" mb-1  ">
            {" "}
            <h5 className="font-weight-bold">Split Type</h5>{" "}
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex mb-3 mt-2">
                <div className="form-check me-3 ">
                  <label className="form-check-label" htmlFor="split-fiat">
                    <input
                      {...register("splitType")}
                      type="radio"
                      className="form-check-input"
                      name="splitType"
                      id="split-flat"
                      value="1"
                      // checked
                    />
                    Flat
                  </label>
                </div>
                <div className="form-check">
                  <label
                    className="form-check-label"
                    htmlFor="split-percentage"
                  >
                    <input
                      {...register("splitType")}
                      type="radio"
                      className="form-check-input"
                      name="splitType"
                      id="split-percentage"
                      value="2"
                      // checked
                    />
                    Percentage
                  </label>
                </div>
              </div>

              <h5 className="border-bottom mt-4 pb-2">
                Revenue Lines{" "}
                <small className="text-xs">(Select a revenue lines)</small>
              </h5>

              <div className="row justify-content-between m-0 pb-4">
                <div className="form-check col-12 mb-3 ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    id="selectAll"
                  />
                  <label className="form-check-label" htmlFor="selectAll">
                    Select All
                  </label>
                </div>
                {/* {FormatArray(revenueLineList)} */}

                <div className="row row-stripped">
                  {settlementAccount &&
                    settlementAccount?.data?.revenues?.map((revenue, index) => (
                      <div
                        className="form-check col-md-4 mb-3 bg"
                        key={revenue.revenueId}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={revenue.revenueId}
                          name={revenue.name}
                          id={revenue.revenueId}
                          checked={revenueIds.includes(revenue.revenueId)}
                          onChange={handleChecked}
                          // {...register(`revenueIds[${index}]`)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={revenue.name}
                        >
                          {revenue.name}
                        </label>
                      </div>
                    ))}
                </div>

                <span className="text-danger text-xs">
                  {formState?.errors?.revenueIds &&
                    Object.values(formState?.errors?.revenueIds).join("")}
                </span>
              </div>

              <div className="row">
                <h5 className="border-bottom mt-4 pb-2 col-12">
                  Third Party Account{" "}
                </h5>

                <SettlementAccount
                  {...{
                    control,
                    register,
                    formState,
                    setValue,
                  }}
                />
              </div>
              <div className="justify-content-end d-flex align-items-center ">
                <Button
                  onClick={toggleModal}
                  className="btn btn-outline-primary bg-transparent mx-3"
                  style={{ minWidth: "25%" }}
                >
                  Cancel
                </Button>

                <AppButton
                  type="submit"
                  label="Proceed"
                  color="blue"
                  style={{ minWidth: "25%", maxHeight: "38px" }}
                  isLoading={isLoading}
                  className="col-3"
                />
              </div>
            </form>
          </FormProvider>
          {/* </ModalBody> */}
          {/* <ModalFooter>
        
        </ModalFooter> */}
        </div>
      </div>
    </div>
  );
};

export default NewSettlementSplit;

const FormatArray = (items) => {
  const [revenueLines, setRevenueLines] = useState([]);

  const n = 3;
  const arr = useCallback(() => {
    const newArr = new Array(Math.ceil(items?.length / n))
      .fill()
      .map((_) => items?.splice(0, n));
    setRevenueLines(newArr);
  }, [setRevenueLines]);
  // setRevenueLines(arr)

  console.log("ARR", revenueLines);

  // return [[123],[7]]?.map((items, index) => (
  //   <div className="row row-stripped">
  //     {items?.map((revenue) => (
  //       <div className="form-check col-md-4 mb-3 bg">
  //         <input
  //           className="form-check-input"
  //           type="checkbox"
  //           value=""
  //           id={`defaultCheck${revenue.id}`}
  //         />
  //         <label className="form-check-label" htmlFor="defaultCheck1">
  //           {revenue.title}
  //         </label>
  //       </div>
  //     ))}
  //   </div>
  // ));

  // return arr;
};
