import { instanceActionTypes } from "../actions/walletInstance";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {
  data: [],
  loadingData: false,
  dataError: null,

  walletHistory: [],
  loadingWalletHistory: false,
  walletHistoryError: null,

  accounts: [],
  loadingAccounts: false,
  accountsError: null,

  creatingInstance: false,
  createInstanceSuccess: false,
  createInstanceError: null,

  editingInstance: false,
  editInstanceSuccess: false,
  editInstanceError: null,

  loadingWalletSummary: false,
  walletSummary: {
    value: "200,567.90",
    currency: "NGN",
    users: 2589,
  },
  walletSummaryError: null,

  loadingWalletChart: false,
  walletChart: [],
  walletChartError: null,
};

const walletInstanceReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(instanceActionTypes.GET_INSTANCES):
      return {
        ...state,
        data: [],
        dataError: null,
        loadingData: true,
      };
    case SUCCESS(instanceActionTypes.GET_INSTANCES):
      return {
        ...state,
        loadingData: false,
        data: actions.payload.data.data,
      };

    case FAILURE(instanceActionTypes.GET_INSTANCES):
      return {
        ...state,
        dataError: actions.payload,
        loadingData: false,
      };

    case REQUEST(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: true,
        createInstanceSuccess: false,
        createInstanceError: null,
      };
    case SUCCESS(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: true,
        createInstanceError: null,
      };

    case FAILURE(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: false,
        createInstanceError: actions.payload.data,
      };

    case REQUEST(instanceActionTypes.RESET_CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: false,
        createInstanceError: null,
      };

    case REQUEST(instanceActionTypes.GET_WALLET_SUMMARY):
      return {
        ...state,
        loadingWalletSummary: true,
        walletSummary: null,
        walletSummaryError: null,
      };
    case SUCCESS(instanceActionTypes.GET_WALLET_SUMMARY):
      return {
        ...state,
        loadingWalletSummary: false,
        walletSummary: actions.payload.data,
        walletSummaryError: null,
      };

    case FAILURE(instanceActionTypes.GET_WALLET_SUMMARY):
      return {
        ...state,
        loadingWalletSummary: false,
        walletSummary: null,
        walletSummaryError: actions.payload.data,
      };

    case REQUEST(instanceActionTypes.GET_WALLET_HISTORY):
      return {
        ...state,
        loadingWalletHistory: true,
        walletHistory: [],
        walletHistoryError: null,
      };
    case SUCCESS(instanceActionTypes.GET_WALLET_HISTORY):
      return {
        ...state,
        loadingWalletHistory: false,
        walletHistory: actions.payload.data,
        walletHistoryError: null,
      };

    case FAILURE(instanceActionTypes.GET_WALLET_HISTORY):
      return {
        ...state,
        walletHistoryError: actions.payload,
        loadingWalletHistory: false,
      };

    case REQUEST(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        loadingAccounts: true,
        accounts: [],
        accountsError: null,
      };
    case SUCCESS(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        loadingAccounts: false,
        accounts: actions.payload.data,
        accountsError: null,
      };

    case FAILURE(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        accountsError: actions.payload,
        loadingAccounts: false,
      };

    case REQUEST(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingWalletChart: true,
        walletChart: [],
        walletChartError: null,
      };
    case SUCCESS(instanceActionTypes.GET_CHART_DATA):
      console.log(actions.payload);
      return {
        ...state,
        loadingWalletChart: false,
        walletChart: converChart(actions.payload.data),
        walletChartError: null,
      };

    case FAILURE(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        walletChartError: actions.payload,
        loadingWalletChart: false,
      };

    case REQUEST(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: true,
        switchStatusSuccess: false,
        switchStatusError: null,
      };
    case SUCCESS(instanceActionTypes.TOGGLE_STATUS):
      console.log(actions.payload);
      return {
        ...state,
        switchingStatus: false,
        switchStatusSuccess: actions.payload.data.data,
        switchStatusError: null,
      };

    case FAILURE(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchStatusError: actions.payload,
        switchingStatus: false,
      };

    default:
      return { ...state };
  }
};

export default walletInstanceReducer;

function converChart(data) {
  const xLabel = [];
  const credited = [];
  const debited = [];

  for (let item of data) {
    xLabel.push(item.transactionDate);
    credited.push(item.credited);
    debited.push(item.debited);
  }
  return {
    labels: xLabel,
    datasets: [
      {
        label: "Credited",
        data: credited,
        fill: true,
        backgroundColor: "rgba(23, 95, 255, 0.1)",
        borderColor: "#175FFF",
      },
      {
        label: "Debited",
        data: debited,
        fill: true,
        backgroundColor: "rgba(255, 105, 105, 0.05)",
        borderColor: "#FF6969",
      },
    ],
  };
}
