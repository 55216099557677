export const getUserDetails = (key = 'user_det') => {
  const jsondetails = localStorage.getItem(key);

  const userDetails = jsondetails ? JSON.parse(jsondetails): null
  // const userDetail = "";
  return userDetails;
};
export const userDetails = () => (getUserDetails() ? getUserDetails() : null);
export const userDetail = getUserDetails() ? getUserDetails().user : null;
export const userToken = getUserDetails() ? getUserDetails().token : null;

export const   setUserDetails = async (payload) => {
  await localStorage.setItem("user_det", JSON.stringify(payload));
};

export const isLoggedIn = () => {
  const details = localStorage.getItem("user_det");
  const userExist = details ? JSON.parse(details) : null;
  return userExist ? true : false;
};

export const signout = async () => {
  await localStorage.setItem("user_det", null);
  await localStorage.removeItem("user_det");
};


