import { Get, Post } from "../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
    GET_SETTLEMENTSPLITS: "[SETTLEMENTSPLIT - INFO] GET_SETTLEMENTSPLITS",
    GET_SETTLEMENTSPLITS_BY_ID: "[SETTLEMENTSPLIT - INFO] GET_SETTLEMENTSPLITS_BY_ID",
    CREATE_SETTLEMENTSPLITS: "[SETTLEMENTSPLIT - INFO] EDIT_SETTLEMENTSPLITS",
    EDIT_SETTLEMENTSPLITS: "[SETTLEMENTSPLIT - INFO] CREATE_SETTLEMENTSPLITS",
};

export const LoadInvoice = createAsyncAction(
  instanceActionTypes.GET_INVOICES,
  async ({ pageNo, pageSize, name }) => {
    const param = {
      pageSize: pageSize,
      from: pageNo,
      parameter: {
        agencyId: 3879,
      },
    };
    return await Post(`Customer/SearchCustomerBDA`, param, {});
  }
);

export const searchSearchSettlementSplits = createAsyncAction(
  instanceActionTypes.GET_SETTLEMENTSPLITS,
  async ({ pageNo, pageSize, name }) => {
    const param = {
      pageSize: pageSize,
      from: pageNo,
      parameter: {
        agencyId: 3879,
      },
    };
    return await Post(`SettlementSplit/SearchSettlementSplits`, param, {});
  }
);

export const searchSettlementSplits = async (payload) => {
    return await Post(`SettlementSplit/SearchSettlementSplits`, payload, {});
   
}
export const createSettlementSplits = async (payload) => {
    return await Post(`SettlementSplit/CreateSettlementSplitApproval`, payload);
   
}

export const fetchBanks= async () => {
  return await (await Get(`SettlementAccount/GetAllBanks`)).data;
};