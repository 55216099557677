import axios from "axios";
import { Login } from "services/login";
import { BehaviorSubject } from "rxjs";

import { baseUrl, config } from "./config.service";
import { signout } from "./user.service";

export const subscriber = new BehaviorSubject(0);

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error?.response?.status === 401) {
      signout();
      // Login();
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      signout();
      // Login();
    }
    return Promise.reject(error);
  }
);

export const Get = async (url, customHeader = {}) => {
  try {
    return await axios.get(`${baseUrl}/${url}`, config(customHeader));
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Post = async (url, data, customHeader = {}) => {
  try {
    return await axios.post(`${baseUrl}/${url}`, data, config(customHeader));
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Patch = async (url, data, customHeader = {}) => {
  try {
    return await axios.patch(`${baseUrl}/${url}`, data, config(customHeader));
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Put = async (url, data, customHeader = {}) => {
  try {
    return await axios.put(`${baseUrl}/${url}`, data, config(customHeader));
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Delete = async (url, customHeader = {}) => {
  try {
    return await axios.delete(`${baseUrl}/${url}`, config(customHeader));
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};
