import React, { useEffect, useState } from "react";
import {
  Table,
  PaginationWrapper,
  Pagination,
  Button,
  // Select,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";
import ActiveCheckbox from "./activeCheckBox";
import { helpers } from "./helper";
import Select from "react-select";
import { useQuery, useMutation } from "react-query";
// import { toast } from 'react-toastify'
import EmptyState from "components/emptyState/EmptyState";
import "../../pages/SettlementAccount/settlementAccount.scss";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingTableContent } from "components/loader/Loader";
import _debounce from "lodash.debounce";
import {
  LoadSettlement,
  getCurrency,
  getRevenue,
  getAllBanks,
  getAllAccountType,
  getSettlementAccountLocationRevenue,
} from "services/settlementAccount";
import { AppButton } from "components/Button/Button";
import { getUserDetails } from "apiServices/user.service";
import { AxiosError } from "axios";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { createSettlementAccount } from "services/settlementAccount";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { Spinner } from "components/Spinner/Spinner";
import { errorMessage, successMessage } from "redux/actions/notification";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import CheckboxGrid from "./checkBoxGrid";
import { getLocations } from "services/invoice";
import "./index.scss";

const ConfigureAccount = ({}) => {
  const validationSchema = Yup.object({
    splitType: Yup.number().required("This field is required"),
  });
  const dispatch = useDispatch();
  const [checked, checkboxChange] = useState(false);
  const history = useHistory();
  // const [checkedAll, checkAllboxChange] = useState(false);
  const [selectAllRevenues, setSelectAllRevenues] = useState();
  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [revenues, setRevenues] = useState();
  const [locations, setLocations] = useState();
  const [revenueData, updateRevenueData] = useState();
  const [locationData, updateLocationData] = useState();
  const [revenueIds, setRevenueIds] = useState([]);
  const [locationIds, setLocationIds] = useState([]);

  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
    setValue,
  } = useForm({
    resolver,
    defaultValues: {
      bankId: { value: undefined, label: undefined },
      accountNumber: undefined,
      accountName: { value: undefined, label: undefined },
      // accountType:{ value: undefined, label: undefined },
      accountType: { value: 1, label: "Primary" },
      agencyId: "",
      currencyId: undefined,
      alias: "",
      isActive: "",
      isPrimaryAccount: false,
      revenueIds: undefined,
      locationIds: undefined,
      isActivePrimaryAccount: false,
      accountId: "",
      //   hasSimilarRevenue: accountRecords.length === 0 ? true : false,
    },
  });
  const userDetails = getUserDetails();
  const accountId = 0;
  const defaultValue = { value: 0, label: "Select " };
  const currentValues = watch();
   let  agencyId = userDetails?.organizationId;

  let settlementAccountId = 0;

  const {
    mutateAsync: settlementData,
    isLoading,
    isError,
    error,
    data,
  } = useMutation(createSettlementAccount, {
    onSuccess: ({ data }) => {
      dispatch(successMessage("configuration successful"));
      // toggleModal();
      reset();
      // refetch()
      // toast.success('Settlement Account Configure successfully')
      // navigate('/')
    },
    onError: (error) => {
      dispatch(errorMessage(error?.data?.message || "Error while creating"));
      // toast.error(error?.response?.data?.errors[0])
    },
  });

  const handleCreate = () => {
    console.log(currentValues, "data");
    let revenueIds = helpers.filterCheckboxId("revenue", revenues);
    let locationIds = helpers.filterCheckboxId("location", locations);
    settlementData({
      bankId: currentValues.bank?.value && parseInt(currentValues.bank?.value),
      accountNumber: currentValues.accountNumber,
      accountName: currentValues.alias,
      accountType:
        currentValues.accountType?.value &&
        parseInt(currentValues.accountType?.value),
      agencyId: userDetails.organizationId,
      // agencyId: 20,
      currencyId:
        currentValues.currency?.value &&
        parseInt(currentValues.currency?.value),
      alias: currentValues.alias,
      isPrimaryAccount: currentValues.accountType.label === "Primary",
      // isActive: status,
      revenueIds: revenueIds,
      locationIds: locationIds,
      //   hasSimilarRevenue: accountRecords.length === 0 ? true : false,
    });
  };
  const {
    data: currencies,
    error: currenciesError,
    isLoading: currenciesLoading,
  } = useQuery(["getCurrency", {}], () => getCurrency(), {
    enabled: true,
    retry: false,
    staleTime: 0,
  });

  const {
    data: banks,
    error: banksError,
    isLoading: banksLoading,
  } = useQuery(["getAllBanks", {}], () => getAllBanks(), {
    enabled: true,
    retry: false,
    staleTime: 0,
  });

  // const {
  //   data: locations,
  //   error: locationsError,
  //   isLoading: locationsLoading,
  // } = useQuery(
  //   ["getLocations", userDetails?.organizationId],
  //   () => getLocations(userDetails?.organizationId),
  //   {
  //     enabled: true,
  //     retry: false,
  //     staleTime:0
  //   }
  // );

  const {
    data: accountTypes,
    error: accountTypesError,
    isLoading: accountTypesLoading,
  } = useQuery(["getAllAccountType", {}], () => getAllAccountType(), {
    enabled: true,
    retry: false,
    staleTime: 0,
  });

  // const {
  //     data: revenues,
  //     error: revenuesError,
  //     isLoading: revenuesLoading,
  // } = useQuery(
  //     ["getRevenue", userDetails?.organizationId],
  //     () => getRevenue(userDetails?.organizationId),
  //     {
  //         enabled: true,
  //         retry: false,
  //         staleTime: 0
  //     }
  // );

  const {
    data: settlementAccount,
    error: settlementAccountError,
    isLoading: settlementAccountLoading,
  } = useQuery(
    [
      "getSettlementAccountLocationRevenue",
      {
        // agencyId: 1,
        // settlementAccountId: 0
      },
    ],
    () => getSettlementAccountLocationRevenue(settlementAccountId, agencyId),
    {
      enabled: true,
      retry: false,
      staleTime: 0,
    }
  );

  const selectAllCheckboxes = (type) => {
    const { revenues, locations } = settlementAccount.data;
    // const newRevenues = settlementAccount.data.locations;
    if (type === "revenue") {
      setSelectAllRevenues(!selectAllRevenues);
      if (!selectAllRevenues) {
        const realRevenues = revenues;
        let revenueActivated = realRevenues.map((item) => {
          item.activated = true;
          return item;
        });
        // this.setState({ revenues: revenueActivated });
        setRevenues(revenueActivated);
      } else {
        const realRevenues = revenues;
        let revenueActivated = realRevenues.map((item) => {
          item.activated = false;
          return item;
        });
        setRevenues(revenueActivated);
        // this.setState({ revenues: revenueActivated });
      }
    }
    if (type === "location") {
      setSelectAllLocations((prev) => !prev);
      // setCount(prevCount => prevCount+1);

      console.log(selectAllLocations);
      if (!selectAllLocations) {
        const realLocations = locations;
        let locationsActivated = realLocations.map((item) => {
          item.activated = true;
          return item;
        });
        setLocations(locationsActivated);
      } else {
        const realLocations = locations;
        let locationsActivated = realLocations.map((item) => {
          item.activated = false;
          return item;
        });
        setLocations(locationsActivated);
      }
    }
  };

  const gridCheckboxChange = (e, type) => {
    const { revenues, locations } = settlementAccount.data;
    let checkedStatus = e.target.checked; //Value of the check input
    let changedPermission = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    // if (type === "active account") {
    //   this.setState({ status: checkedStatus });
    // }

    // if (type === "active primary account") {
    //   this.setState({ isActivePrimaryAccount: checkedStatus });
    // }

    let updatedRecord;
    //  Variable to hold the record that was updated

    if (type === "revenue") {
      updatedRecord = helpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        revenues
      );
      // this.setState({ revenueData: updatedRecord });

      updateRevenueData({ revenueData: updatedRecord });
    }
    if (type === "location") {
      updatedRecord = helpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        locations
      );
      updateLocationData({ locationData: updatedRecord });
    }
  };

  useEffect(() => {
    if (settlementAccount && settlementAccount.data) {
      setRevenues(settlementAccount.data.revenues);
      setLocations(settlementAccount.data.locations);
    }
  }, [settlementAccount]);
  //     console.log(errors,"err");
  // console.log(locations?.data,"location");
  // console.log(settlementAccount,"settle");

  return (
    <div
      className="w-100"
      // style={{overflowX: 'scroll'}}
    >
      {/* <Modal isOpen={!!show} toggle={toggleModal} centered scrollable size={size}>
                <ModalHeader toggle={toggleModal} className="font-weight-bold title  m-1 py-1"></ModalHeader>
                <ModalBody 
                style={{
                    overflowY: "scroll",
                    // overflowX: "scroll",
                    maxHeight: "700vh",
                    padding: "0px 20px",
                }}
                > */}
      <div className="d-flex justify-content-between align-items-center border-bottom w-100 mb-5">
        <h5 className="">New Settlememnt Account</h5>

        <button
          className="btn btn-outline-primary rounded-pill"
          onClick={() => history.push("/services/settlement-account")}
        >
          <span className="text-xs  " style={{ marginRight: "8px" }}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </span>
          Back
        </button>
      </div>
      {/* <> */}
      <form onSubmit={handleSubmit(handleCreate)}>
        <div className="row">
          <div className="form-group col-md-4 mb-3">
            <label className="mb-2" for="exampleFormControlSelect1">
              Bank Name
            </label>
            <Select
              placeholder="Select"
              options={banks?.data?.map((org) => {
                return {
                  value: org.id,
                  label: org.name,
                };
              })}
              onChange={(newValue) =>
                setValue("bank", newValue, {
                  shouldValidate: true,
                })
              }
              isLoading={banksLoading}
              defaultValue={defaultValue}
              // styles={customStyles}
            />
          </div>
          <div className="form-group col-md-4 mb-3">
            <label className="mb-2" for="exampleFormControlInput1">
              Account Number *
            </label>
            <input
              loading={false}
              name="accountNumber"
              {...register("accountNumber")}
              className="form-control "
              id="exampleFormControlInput1"
              placeholder="Enter details..."
            />
          </div>

          <div className="form-group col-md-4 mb-3">
            <label className="mb-2" for="exampleFormControlInput1">
              Alias(optional)
            </label>
            <input
              name="alias"
              {...register("alias")}
              className="form-control "
              id="exampleFormControlInput1"
              placeholder="Enter details"
            />
          </div>

          <div className="col-md-4 form-group mb-3">
            <label className="mb-2" htmlFor="">
              Currency
            </label>
            <Select
              placeholder="Select"
              options={currencies?.data?.map((cur) => {
                return {
                  value: cur.id,
                  label: cur.name,
                };
              })}
              onChange={(newValue) =>
                setValue("currency", newValue, {
                  shouldValidate: true,
                })
              }
              isLoading={currenciesLoading}
              defaultValue={defaultValue}
              // styles={customStyles}
            />
          </div>
          <div className="col-md-4 form-group mb-3">
            <label className="mb-2" htmlFor="">
              Account Type
            </label>
            <Select
              placeholder="Select"
              options={accountTypes?.data?.map((org) => {
                return {
                  value: org.id,
                  label: org.name,
                };
              })}
              onChange={(newValue) =>
                setValue("accountType", newValue, {
                  shouldValidate: true,
                })
              }
              isLoading={accountTypesLoading}
              defaultValue={defaultValue}
              // styles={customStyles}
            />
          </div>
          <div className={"mt-5 col-md-4 form-group mb-3"}>
            <ActiveCheckbox
              text={"Set as active account"}
              checkboxChange={() => checkboxChange(!checked)}
              checked={checked}
            />
          </div>
        </div>
      </form>

      {/* </> */}

      {/* </ModalBody> */}
      {/* <ModalFooter> */}
      <div className={"mt-4"}>
        <h6 className="divide-y-2">Account Mapping</h6>
        {currentValues.accountType && currentValues.accountType.value === 1 && (
          <div>
            <span className={"d-block"}>
              Primary accounts are mapped to all revenue lines and locations.
            </span>
            <span className={"d-block"}>
              To map this account to a specific revenue line or location, change
              the account type to “Secondary”
            </span>
          </div>
        )}
        {currentValues.accountType && currentValues.accountType.value === 2 && (
          <div>
            <>
              <div className={"mt-4"}>
                {settlementAccount.data.locations?.length === 0 && (
                  <EmptyState description={"No location record"} />
                )}
                {settlementAccount.data.locations?.length > 0 && (
                  <CheckboxGrid
                    title={"Select locations you want to map this account to"}
                    checkAll={selectAllLocations}
                    label={!selectAllLocations ? "Select all" : "Deselect all"}
                    displayCheckAllBoxes={true}
                    checkAllBoxes={() => selectAllCheckboxes("location")}
                    data={locations}
                    checkboxChange={(e) => gridCheckboxChange(e, "location")}

                    // checkboxChange={(e) => this.checkboxChange(e, "location")}
                  />
                )}
              </div>
              <div className={"mt-4"}>
                {settlementAccount.data.revenues?.length === 0 && (
                  <EmptyState description={"No revenue record"} />
                )}
                {settlementAccount.data.revenues?.length > 0 && (
                  <CheckboxGrid
                    title={
                      "Select revenue lines you want to map this account to"
                    }
                    checkAll={selectAllRevenues}
                    label={!selectAllRevenues ? "Select all" : "Deselect all"}
                    displayCheckAllBoxes={true}
                    checkAllBoxes={() => selectAllCheckboxes("revenue")}
                    data={revenues}
                    checkboxChange={(e) => gridCheckboxChange(e, "revenue")}
                  />
                )}
              </div>
            </>
          </div>
        )}
      </div>
      <div className="justify-content-end mt-3">
        <button
          // onClick={toggleModal}
          className="btn btn-outline-primary rounded-pill mx-3"
        >
          Cancel
        </button>
        <AppButton label="Proceed" color="blue" onClick={handleCreate} />
        {/* <Button
                        // color={show?.status ? "danger" : "primary"}
                        color="blue"
                        onClick={onSubmit}
                        className="submit-btn"
                    > Proceed */}
        {/* {show?.status ? "Deactivate" : "Proceed"}{" "} */}
        {/* {processing && <Spinner size="sm" color="light" />} */}
        {/* </Button> */}
        {/* </ModalFooter> */}
      </div>
      {/* </Modal> */}
    </div>
  );
};

export default ConfigureAccount;
