import { Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
// import useFeeTypes from "../../../../../../hooks/useFeeTypes";
import { useEffect } from "react";
// import { customStyles } from "../../../../../../utils/selectStyles";
import { Button } from "reactstrap";
import { useBanks } from "hooks/useBanks";
import { useCurrency } from "hooks/useCurrency";
import '../settlementSplit.scss'

 

export default function SettlementAccount({
  control,
  register,
   
  formState,
  setValue,
}) {
  const { banks, getBanks } = useBanks();
  const { errors } = formState;

  const { currencies, getCurrency } = useCurrency();



  const { fields, append, remove } = useFieldArray({
    control,
    name: "settlementAccounts",
  });

//   useEffect(() => {
//     if (entryType?.name) {
//         setValue('operationType', {
//             value: entryType?.id,
//             label: entryType?.name,
//         }, {
//             shouldValidate: true,
//         })
//     }
// }, [entryType?.name])

  useEffect(() => {
    getBanks();
    getCurrency();
  }, []);
 

  return (
    <div className="create-settlement-container">
      <div className="mt-4">
        {fields.map((item, index) => (
          <div
            key={item.id}
            className=" row justify-content-start align-items-center mb-4"
          >
             <div className="form-group col-md-4 mb-3">
              <label className="mb-2" for="exampleFormControlSelect1">
                Bank Name
              </label>
              <Select
                placeholder="Bank.."
                options={banks?.map((ele) => {
                  return {
                    value: ele.id,
                    label: ele.name,    
                    code : ele.code        
                  };
                })}
                onChange={(newValue) => {
                  setValue(
                    `settlementAccounts[${index}].bankId`,
                    parseInt(newValue.value)
                  );
                  setValue(
                    `settlementAccounts[${index}].bankCode`,
                    newValue.code
                  );
                  setValue(
                    `settlementAccounts[${index}].validatedAccountName`,
                    newValue.label
                  );
                  setValue(
                    `settlementAccounts[${index}].isAccountNumberValidated`,
                   true
                  );
                  
                }}
                className=""
                //   styles={customStyles}
                //@ts-ignore
                defaultValue={item.bankId}
              />
              <span className="text-danger">
                {errors?.settlementAccounts &&
                  errors?.settlementAccounts[index]?.bankId?.message}
              </span>
            </div>
          
            <div className="form-group col-md-4 mb-3">
              <label className="mb-2" for="exampleFormControlSelect1">
                Account Number
              </label>
              <input
                name={`settlementAccounts[${index}].accountNumber`}
                {...register(`settlementAccounts[${index}].accountNumber`, {
                  required: "Required",
                })}
                //@ts-ignore
                defaultValue={item.accountNumber}
                type="text"
                className="form-control"
                placeholder="Account Number"
              />

              <span className="text-danger text-xs">
                {errors?.settlementAccounts && errors?.settlementAccounts[index]?.accountNumber?.message}
              </span>
            </div>
            {/* <div className="form-group col-md-4 mb-3">
              <label className="mb-2" for="exampleFormControlSelect1">
                Account Name
              </label>
              <input
                name={`settlementAccounts[${index}].accountId`}
                {...register(`settlementAccounts[${index}].accountId`, {
                  required: "Required",
                })}
                //@ts-ignore
                defaultValue={item.accountId}
                type="number"
                className="form-control"
                placeholder="Account Name "
              />
              <span className="text-danger text-xs">
                {errors?.settlementAccounts &&
                  errors?.settlementAccounts[index]?.accountId?.message}
              </span>
            </div> */}
           

            <div className="form-group col-md-4 mb-3">
              <label className="mb-2" for="exampleFormControlSelect1">
                Currency
              </label>
              <Select
                placeholder="Currency.."
                options={                
                  currencies?.map((ele) => {
                    return {
                      value: ele.id,
                      label: ele.code,
                    };
                  })
                }
                onChange={(newValue) => {
                  setValue(
                    `settlementAccounts[${index}].currencyId`,
                    parseInt(newValue.value)
                  );
                  setValue(
                    `settlementAccounts[${index}].currencyCode`,
                    newValue.label
                  );
                }}
                className="w-56"
                //   styles={customStyles}
                //@ts-ignore
                defaultValue={item.currencyId}
              />
            </div>
            <div className="form-group col-md-4 mb-3">
              <label className="mb-2" for="exampleFormControlSelect1">
                Third Party Share
              </label>
              <input
                name={`settlementAccounts[${index}].thirdPartyShare`}
                {...register(`settlementAccounts[${index}].thirdPartyShare`, {
                  required: "Required",
                })}
                
                //@ts-ignore
                defaultValue={item.thirdPartyShare}
                type="number"
                className="form-control"
                placeholder="Third Party Share"
              />
              <span className="text-danger text-xs">
                {errors?.settlementAccounts &&
                  errors?.settlementAccounts[index]?.thirdPartyShare?.message}
              </span>
            </div>
            <div className="col-md-4 mt-3 ">
              <Button
                color="danger"
                type="button"
                onClick={() => remove(index)}
                // className="border bg-danger px-2 py-1 h-6 flex justify-content-center bg-danger text-sm text-white"
              >
                Delete
              </Button>

              {/* <Button type="submit" label="Save" color="primary" >Save</Button> */}
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex gap-5 align-items-center">
        <div
          onClick={() => {
            append({
              accountNumber: "",
              bankCode: "",
              currencyId: "",
              thirdPartyShare: 0,
            });
          }}
          className="cursor-pointer font-weight-bold d-flex align-items-center text-primary gap-2 mt-3"
        >
          <div
            className="add-settlement rounded-circle"
            
          >
            <span className="text-white" style={{ fontSize: "18px" }}>
              +
            </span>
          </div>
          <span>Add another settlement </span>
        </div>
        {/* <Button
          type="submit"
          label="Save"
          color=""
          className="border-0 p-0 mt-3 text-primary font-weight-bold"
        >
          Save
        </Button> */}
      </div>
    </div>
  );
}
