import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import "./filter.scss";

const FilterDropdown = ({ items, displayKey, onItemClick, onToggle, children, showIcon, background, showSelectedValue, value, buttonStyle, customCaret, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      toggle={() => {
        setIsOpen(!isOpen);
        onToggle && onToggle({ isOpen });
        onClick && onClick();
      }}
      isOpen={isOpen}
    >
      <DropdownToggle
        color="transparent"
        className="toggle-btn justify-content-center"
        style={{
          background: background ? background : "#f4f5f6",
          ...buttonStyle,
          color: buttonStyle?.color ? buttonStyle.color : "#677684",
        }}
      >
        {showIcon && <i class="ri-filter-3-line"></i>}&nbsp; {showSelectedValue ? value : "Filter"} &nbsp;
        {customCaret ? customCaret() : <i className={`ri-arrow-drop-${isOpen ? "up" : "down"}-line`}></i>}
      </DropdownToggle>
      {children && (
        <DropdownMenu container="body" right>
          {children}
        </DropdownMenu>
      )}
      {items && !children && (
        <DropdownMenu container="body" right>
          {items.map((item, idx) => (
            <DropdownItem
              key={idx}
              style={{ background: typeof item === "string" ? (item === value ? "#f4f5f6" : "") : item[displayKey] === value ? "#f4f5f6" : "" }}
              onClick={() => {
                onItemClick && onItemClick(item);
              }}
            >
              {typeof item !== "string" && displayKey ? item[displayKey] : item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default FilterDropdown;
