import React, { useState } from "react";
import { AppButton } from "components/Button/Button";
import URLS from "helpers/urls";
import { useHistory } from "react-router-dom";
import { onboardService } from "services/login";
import { Typography } from "vgg-ui-react/dist/components/Core";
import { getUserDetails, setUserDetails } from "apiServices/user.service";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "redux/actions/notification";

const GeneralInfoTab = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getUserDetails("user_details");
  const payload = user && {
    accessToken: user?.accessToken,
    ssoId: user?.user?.ssoId,
    clientId: user?.user?.clientId,
    firstName: user?.user?.firstName,
    lastName: user?.user?.lastName,
    email: user?.user?.email,
    phoneNumber: user?.user?.phoneNumber,
  };
console.log(payload, "onboardpayload");
  const onBoard = async (e) => {
    e.preventDefault()
    try {
      setLoading(true);
      const res = await onboardService(payload);
      // console.log("DATA:", res);
      if (res?.data) {
        setUserDetails(res.data)
        history.push(URLS.service);
      } else {
        history.push(URLS.onboard);
        // document.location.href = `${document.location.origin}/onboard`;
      }
      setLoading(false);
    } catch (error) {
      dispatch(
        errorMessage(
          error?.data?.message || "Error while onboarding"
        )
      );
    }
  };
  return (
    <div
      className="row my-4 px-4"
      style={{ height: "60vh", overflowY: "auto" }}
    >
      <div className="col-6">
        <Typography
          typographyVariant="section-title"
          text="Overview"
          className="pg-title"
        />
        <div className="sp-pt--8">
          <p>
            We allow companies to create wallets and accounts for their users.
            We also provide account management and borderless transactions on
            the wallets.
          </p>
        </div>

        <div className="sp-mt--48">
          <div className="sp-pt-24">
            <Typography
              typographyVariant="section-title"
              text="Documentation"
              className="pg-title"
            />

            <div className="sp-pt--8">
              <ul>
                <li className="mb-3">
                  <a
                    href="https://developer.africaone.io"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Getting Started right here &nbsp;
                    <i className="ri-external-link-line"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://developer.africaone.io"
                    rel="noreferrer"
                    target="_blank"
                  >
                    How to get your API keys &nbsp;
                    <i className="ri-external-link-line"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3"></div>
      <div className="col-3">
        <div className="mb-3 ">
          <AppButton
            label="Onboard Provider"
            color="blue"
            onClick={onBoard}
            isLoading={loading}
          />
        </div>
        <div className="sp-mb--48">
          <Typography
            typographyVariant="section-title"
            text="Additional Information"
            className="pg-title"
          />
        </div>
        <div>
          <p>
            <span className="font-weight-bold">Runs on:</span>&nbsp; Kenel Cloud
            Service
          </p>
          <p>
            <span className="font-weight-bold">Type:</span>&nbsp;{" "}
            <span className="sp-text-color--blue">SaaS and APIs</span>
          </p>
          <p>
            <span className="font-weight-bold">Last Updated:</span>&nbsp;
            20/02/2021
          </p>
          <p>
            <span className="font-weight-bold">Category:</span>&nbsp;
            <span className="sp-text-color--blue">Analytics, Finance</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoTab;
