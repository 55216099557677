import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeMessage } from "../../redux/actions/notification";
import SuccessIcon from "../../assets/images/success.svg";
// import WarningIcon from "../../assets/images/warning.svg";
import ErrorIcon from "../../assets/images/error.svg";
import onboarding from "./icons";
import "./notifications.scss";

const Notification = (props) => {
  

  const dispatch = useDispatch();

  const { success, warning, error } = useSelector(({ notification }) => {
    return {
      success: notification?.successMessage,
      warning: notification?.warningMessage,
      error: notification?.errorMessage,
    };
  });
  useEffect(() => {
    setTimeout(() => dispatch(closeMessage()), 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, warning, error]);
  return (
    <>
      {success && (
        <div className="notification-container">
          <div className="success">
            <img src={SuccessIcon} alt="" />
            {success}
            <span onClick={() => dispatch(closeMessage())}>
              {onboarding("#1E944D")}
            </span>
          </div>
        </div>
      )}
      {/* {warning && (
        <div className="notification-container">
          <div className="warning">
            <img src={WarningIcon} alt="warning icon" />
            {warning}
            <span onClick={() => dispatch(closeMessage())}>{Icon.onboarding("#D66F0F")}</span>
          </div>
        </div>
      )} */}
      {error && (
        <div className="notification-container">
          <div className="error">
            <img src={ErrorIcon} alt="" />
            {error || "Error occurred while processing this request"}
            <span onClick={() => dispatch(closeMessage())}>
              {onboarding("#CA1B1B")}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
