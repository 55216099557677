import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { store } from "redux/store";
import App from "./App";
import "./index.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

// render microfrontend (1)

window.renderInvoiceServiceApp = ({ containerId, history, userDetail, url }) => {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <QueryClientProvider client={queryClient}>
          <App url={url} user={userDetail} history={history} isChild={true} />
        </QueryClientProvider>
      </Suspense>
    </Provider>,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function (2)
window.unmountInvoiceServiceApp = ({ containerId }) => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

// Mount to root if it is not a micro frontend (3)
if (!document.getElementById("InvoiceServiceApp-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <App url="" />
          </QueryClientProvider>
        </Router>
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );
}

