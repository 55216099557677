import React from "react";
import { useHistory } from "react-router-dom";
import { settingsMenu } from "routes/AppMenu/servicemenu";
import "./Settings.scss";

const SettingsPage = () => {
  const history = useHistory();
  return (
    <div className="settings_page ">
      <h5 className="">Preferences</h5>
      <ul>
        {settingsMenu?.map((item) => (
          <li
          style={{fontSize: '13px'}}
          className="font-weight-bold"
            onClick={() => {
              history.push(item.link);
            }}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsPage;
