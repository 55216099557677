import { Post, Get } from "../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
    GET_SETTLEMENTS: "[SETTLEMENT - INFO] GET_SETTLEMENTS",
    GET_CURRENCIES: "[SETTLEMENT - INFO] GET_CURRENCIES",

};

// export const getAdminDashboard = async (startDate,endDate) => {
//   return await (await Get(`Dashboard/AdminDashboard?startDate=${startDate}&endDate=${endDate}`)).data;
// };

export const getAdminDashboard = async (startDate, endDate, currencyId) => {
    const response =  await Get(
        // `Dashboard/AdminDashboard?startDate=${startDate ? "?startDate=" + startDate : ""}${startDate && endDate ? "&endDate=" + endDate : ""}&currencyId=${currencyId}`
        `Dashboard/AdminDashboardBDA?startDate=${startDate}&endDate=${endDate}&currencyId=${currencyId}`
    )
    return response.data
}

export const getSettlementAccountLocationRevenue = async (settlementAccountId, agencyId) => {
  return await (await Get(`/SettlementAccount/GetSettlementAccountLocationRevenueBDA?agencyId=${agencyId}&settlementAccountId=${settlementAccountId}`)).data;
};










