import { getUserDetails } from "apiServices/user.service";
import { AppButton } from "components/Button/Button";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import _debounce from "lodash.debounce";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { searchInvoice } from "services/invoice";
import { errorMessage } from "redux/actions/notification";
import { searchSearchSettlementSplits } from "services/settlementAccount";
import Table from "vgg-ui-react/dist/components/Core/Table";
import NewSettlementSplit from "../../components/SettlementSplit/NewSettlementSplit";
import { revenueLineList } from "../../components/SettlementSplit/revenue-line";
import SettlementSplitTable from "../../components/SettlementSplit/SettlementSplitTable";
import { useHistory } from "react-router-dom";

const SettlementSplit = () => {
  const [addNew, setAddNew] = useState(false);
  const history = useHistory()
  const [revenuesLines, setRevenueLines] = useState([]);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const userDetails = getUserDetails();
  const [filters, setFilters] = useState({
    pageSize: 10,
    from: 0,
    parameter: {
      agencyId: userDetails?.organizationId,
      // agencyId:  userDetails?.organization,
      searchQuery: "",
      // agency: userDetails?.organization,
    },
  });

  const { isLoading, data, error } = useQuery(
    [
      {
        filters,
      },
    ],
    () => searchSearchSettlementSplits(filters),
    {
      enabled: true,
      retry: false,
      staleTime: 0,
      notifyOnChangeProps: ["data"],
      onSuccess: (res) => {
      },
      onError: (err) => {
        console.log("err", err);
        setIsError(true);
        dispatch(
          errorMessage(err?.data?.message || "An error occured")
        );
        setIsError(false);
      },
    }
  );

  const onPageClick = (evt) => {
    setFilters((prev) => ({ ...prev, from: evt }));
  };
  const addNewSplits = () => {
    // console.log(rowData);
    // setAddNew(!addNew);
    history.push('/services/settings/configure-settlemement-split');
   
  };
  const handleSearch = _debounce((event) => {
    if (!event.target.value) {
      setFilters({
        pageSize: 10,
        from: 0,
        parameter: {
          agencyId: userDetails?.organizationId,
          searchQuery: "",
          agency: userDetails?.organization,
        },
      });
    }
    setFilters({
      ...filters,
      searchQuery: event.target.value,
    });
    // cb(true);
  }, 1000);

  return (
    <div className="invoice_container  w-100">
      <div className="row justify-content-between align-items-center ">
        <div className="col-md-4   mb-4 mb-md-0">
        <div className="search-container ">
          <input
            type="search "
            placeholder="search"
            className="form-control "
            onChange={handleSearch}
          />
          <i className="ri-search-line"> </i>
        </div>

        </div>
        <div className="col-md-2 ">
          
            <div className="">
              <AppButton
                label="Configure"
                color="blue"
                // isLoading={invoiceSearchLoading && isLoading} 
                onClick={addNewSplits}
              />
            
          </div>
        </div>
      </div>

      <section className="mt-5">
        {isLoading ? (
          <Table variant="unbounded">
            <thead>
              <tr>
                <th>Service Type</th>
                <th>Split Type</th>
                <th>Settlement Account</th>
                <th className="align-middle text-center">Actions</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={4} />
            </tbody>
          </Table>
        ) : data?.results?.length === 0 ? (
          <div className="mt-8">
            <EmptyState description={data.message} />
          </div>
        ) : isError ? (
          "error"
        ) : (
          <SettlementSplitTable
            data={data}
            filters={filters}
            onPageClick={onPageClick}
          />
        )}
      </section>

      
    </div>
  );
};

export default SettlementSplit;
