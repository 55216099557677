import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { menu } from "./AppMenu/servicemenu";
import ServiceDetailsRoutes from "./ServiceRoutes";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import { useLocation, Link } from "react-router-dom";
import "../pages/ServiceDetails/service-details.scss";

const AppLayout = () => {
  // const [activeTab, setActiveTab] = useState("1");
  const  {pathname} = useLocation()

  console.log(pathname);
  console.log(pathname.includes('/services'));
  
  const activeNav = (item) => {
    if (pathname.includes(item.link)) {
      return "font-weight-bold border-bottom border-primary border-2 p-2";
    } else {
      return "font-weight-bold text-secondary";
    }
  };
  
  return (
    <div className="my-4 px-4">
      
      <Nav tabs  className="border-0" >
        {menu?.map((item) => (
          <NavItem>
            <Link
            style={{fontSize: '12px'}}
              to={item.link}

               
              className={activeNav(item)}
              
            >
              {item.title}
            </Link>
          </NavItem>
        ))}
      </Nav>
      <main className=" my-5"  >
        <ServiceDetailsRoutes />
      </main>
    </div>
  );
};

export default AppLayout;
