import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import PropTypes from "prop-types";

import "./tableActionDropdown.scss";

const TableActionDropdown = ({ actions, data, level, horizontal }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" className="dd-icon">
        <span title="Actions">{horizontal ? <i className="ri-more-fill"></i> : <i className="ri-more-2-fill"></i>}</span>
      </DropdownToggle>
      <DropdownMenu right className="action-dropdown">
        {actions.map(action => (
          <DropdownItem key={action.id} to="#" onClick={e => action.method(e, data, level && level)}>
            {action.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

TableActionDropdown.defaultProps = {
  data: null,
  level: null,
  actions: [],
  horizontal: true,
};

TableActionDropdown.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  level: PropTypes.number,
  horizontal: PropTypes.bool,
};

export default TableActionDropdown;
