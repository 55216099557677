import React from "react";

  const onboarding = (color) => {
    return (
      <span className="icon">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.99999 4.05757L8.29999 0.757568L9.24266 1.70024L5.94266 5.00023L9.24266 8.30024L8.29999 9.2429L4.99999 5.9429L1.69999 9.2429L0.757324 8.30024L4.05732 5.00023L0.757324 1.70024L1.69999 0.757568L4.99999 4.05757Z"
            fill={color}
          />
        </svg>
      </span>
    );
  }
  export default onboarding;
