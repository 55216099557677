import React from "react";
import { Input } from "reactstrap";


export const helpers = {

tableContent: function (data, checkboxChange, disableAllCheckboxes) {
    let tableRowsContent = [];

    const rows = [...Array(Math.ceil(data.length / 3))];
    const dataRows = rows.map((row, idx) => data.slice(idx * 3, idx * 3 + 3));
    tableRowsContent = dataRows.map((row, idx) => {
      return (
        <tr key={idx}>
          {row.map((item, index) => (
            <td key={index}>
              <span style={{ marginRight: "15px" }}>
                <Input
                  style={{
                    cursor: "pointer",
                    marginLeft: "0.75rem",
                    marginTop: "0.2rem",
                  }}
                  type="checkbox"
                  checked={item.activated || false}
                  onChange={checkboxChange}
                  disabled={disableAllCheckboxes}
                />
              </span>
              <label>{item.name}</label>
            </td>
          ))}
        </tr>
      );
    });
    return tableRowsContent;
  },

  updatePermissionType: function (
    checkedStatus,
    changedPermission,
    permissionType
  ) {
    let updatedRecord = permissionType.map((item) => {
      if (item.name === changedPermission) {
        item.activated = checkedStatus;
      }
      if (item.address === changedPermission) {
        item.activated = checkedStatus;
      }
      return item;
    });

    return updatedRecord;
  },

  filterCheckboxId: (type, data) => {
    if (type === "permissions") {
      const filteredPermissions = data
        .filter((item) => item.activated === true)
        .map((item) => item.permissionId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "revenue") {
      const filteredPermissions = data
        .filter((item) => item.activated === true)
        .map((item) => item.revenueId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "location") {
      const filteredPermissions = data
        .filter((item) => item.activated === true)
        .map((item) => item.locationId);
      return filteredPermissions ? filteredPermissions : [];
    }
  },
}