import { Post } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
    GET_REVENUES: "[REVENUE - INFO] GET_REVENUES",
};

export const LoadRevenue = createAsyncAction(
  instanceActionTypes.GET_REVENUES,
  async ({ pageNo, pageSize, qry }) => {
    const param = {
      pageSize: pageSize,
      from: pageNo,
      parameter: {
        searchQuery: qry,
      },
    };
    return await Post(`Revenue/SearchRevenueBDA`, param, {});
  }
);
