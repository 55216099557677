import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
// import {useHistory} from "react-router"
import URLS from "helpers/urls";
import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import ConfigureWallet from "pages/ConfigureWallet/ConfigureWallet";
import WalletConfiguration from "pages/WalletConfiguration/WalletConfiguration";
import Notifications from "components/notifications/notifications";
// import ConfigureAccount from "components/settlementAccount/configureSettlement";
import { useDispatch, useSelector } from "react-redux";
import AppLayout from "routes/AppLayout";
import GeneralInfoTab from "components/generalInfoTab/GeneralInfoTab";
import { Header } from "components/Header/Header";
import CustomSpinner from "components/CustomSpinner/CustomSpinner";
import PrivateRoute from "routes/PrivateRoute";
import { useMutation } from "react-query";
import axios from "axios";
import { Login, onboardService, validateClientService } from "services/login";
import { isLoggedIn, setUserDetails, signout } from "apiServices/user.service";
import { successMessage } from "redux/actions/notification";

function App({ url, user, isChild, history }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const token = user && user?.accessToken;
  const userDetails = user && user?.user;
  console.log("getUser", userDetails);
  // set;
  const payload = {
    accessToken: token,
    ssoId: userDetails?.ssoId,
    clientId: userDetails?.clientId,
    // clientId: 15,
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    email: userDetails?.email,
    // email:  "kenasrt@gmail.com",
    phoneNumber: userDetails?.phoneNumber,

    
  };
  const [verifyToken, setVerifyToken] = useState(false);
  // setUserDetails()

  const path = history;
  console.log({ path });
  
  const onboard = async () => {
    console.log(payload);
    try {
      if (!localStorage.getItem("user_det")) {
        setLoading(true);
        const res = await validateClientService(payload);
        // console.log("RES", res);
        // console.log("statusCode", res.status);
        // console.log("verify", verifyToken);

        // const data = {
        //   userName: "tunde.ojediran@venturegardengroup.com",
        //   password: "Password1@",
        //   // password: "Password1",
        //   rememberMe: true,
        // };

        // const res = await Login(data);

        if (res?.data && res?.status === 404) {
          setUserDetails(res.data);
          setVerifyToken(true);
          // setLoading(false);
        } else if (res?.data && res?.status === 200){
          setUserDetails(res.data);
          setVerifyToken(true);
          // setLoading(false);
        } else if (res?.status === 401) {
          setVerifyToken(false);
          signout()
          // setLoading(false);
        }
        setLoading(false);
      }

    } catch (error) {
      setLoading(false);
      setVerifyToken(false);
       signout()
    }
  };

  useLayoutEffect(() => {
    onboard();
  }, []);
  
  useEffect(() => {
    if (!token || !userDetails) {
      signout();
     }

  }, [token, userDetails]);
  return (
    <div className={`container-fluid`}>
      <Notifications />
      <Header />

      <BrowserRouter>
        {loading ? (
          <div 
          className="content-loading"  style={{ height: "75vh" }}
          >
            {/* <CustomSpinner height="75vh" /> */}
          </div>
        ) : verifyToken ? (
          <Switch>
            {/* <Redirect to="/services/dashboard" /> */}
            <Route path={URLS.service} component={AppLayout} />
            <Route path="/">{<Redirect to={URLS.service} />}</Route>
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/onboard" component={GeneralInfoTab} />
            <Route path={URLS.service} component={AppLayout} />
            <Route path="/">{<Redirect to={URLS.onboard} />}</Route>
          </Switch>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
