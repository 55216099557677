import { userDetails } from "./user.service";

export const baseUrl = process.env.NODE_ENV === "development" ? "https://passerelle.test.vggdev.com/api" : `${window.env?.passerelleUrl}/api`;
// export const baseUrl = process.env.NODE_ENV === "development" ? "https://bda-new.test.vggdev.com/api" : `${window.env?.invoiceServiceBaseApiUrl}/api`;

export const config = (customHeader = {}) => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().access_token}`;
    return { headers: { Authorization: bearerToken, ...customHeader } };
  }
  
  return null;
};
