import { notificationType } from "../actions/notification";

const INTIAL_STATE = {};

const notificationReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case notificationType.SUCCESS_NOTIFICATION:
      return { ...state, successMessage: actions.payload, errorMessage: null, warning: null };
    case notificationType.ERROR_NOTIFICATION:
      return { ...state, errorMessage: actions.payload, successMessage: null, warningMessage: null };
    case notificationType.WARNING_NOTIFICATION:
      return { ...state, warningMessage: actions.payload, errorMessage: null, successMessage: null };
    case notificationType.CLOSE_NOTIFICATION:
      return { ...state, warningMessage: null, errorMessage: null, successMessage: null };
    default:
      return { ...state };
  }
};

export default notificationReducer;
