import { Spinner } from "components/Spinner/Spinner";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Table from "vgg-ui-react/dist/components/Core/Table";

const SettlementSplitDetails = ({
  show,
  toggleModal,
  onSubmit,
  processing,
  size,
  splitDetails,
}) => {
  const [data] = useState([1, 2, 3, 4]);
  return (
    <Modal isOpen={show} toggle={toggleModal} centered size={size}>
      <ModalHeader toggle={toggleModal} className="font-weight-bold title py-4">
        {show?.status ? "Deactivate" : "Activate"} Instance
      </ModalHeader>
      <ModalBody style={{ overflowX: "auto", padding: "20px" }}>
        <Table variant="unbounded">
          <thead>
            <tr className="">
              <th>Bank Name</th>
              <th>Account Name</th>
              <th> Account Number</th>
              <th> Split Value</th>
            </tr>
          </thead>
          <tbody style={{ borderTop: "none" }}>
            {splitDetails &&
              splitDetails?.settlementSplitAccounts?.map((item, idx) => (
                <tr key={idx}>
                  <td> {item?.bankName}</td>
                  <td> {item?.accountName}</td>
                  <td> {item?.accountNumber}</td>
                  <td>{item?.thirdPartyShare}</td>
                </tr>
              ))}{" "}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <span
          data-testid="cancel-btn"
          onClick={toggleModal}
          className="px-3 cancel-btn"
        >
          Cancel
        </span>
        <Button
          color={show?.status ? "danger" : "primary"}
          onClick={onSubmit}
          className="submit-btn"
        >
          {show?.status ? "Deactivate" : "Activate"}{" "}
          {processing && <Spinner size="sm" color="light" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SettlementSplitDetails;
