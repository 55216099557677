import { useState } from "react";
import { useQuery } from "react-query";
import { fetchCurrency } from "services/invoice";

export function useCurrency() {
  const [currencies, setCurrencies] = useState([]);

  const { refetch } = useQuery(["GetCurrencies"], () => fetchCurrency(), {
    enabled: true,
    retry: 2,
    staleTime:0,
    onSuccess: (response) => {
        console.log(response);
      setCurrencies(response.data);
    },
  });

  const getCurrency = () => {
    refetch();
  };

  return { currencies, getCurrency };
}
