import { useState } from "react";
import { useQuery } from "react-query";
import { fetchBanks } from "services/settlements";

export  function useBanks() {
  const [banks, setBanks] = useState([]);

  const { refetch } = useQuery(["GetAllBanks"], () => fetchBanks(), {
    enabled: true,
    retry: 2,
    onSuccess: (response) => {
      setBanks(response.data);
    },
  });

  const getBanks = () => {
    refetch();
  };

  return { banks, getBanks };
}
