import { Post } from "../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
    GET_CUSTOMERS: "[CUSTOMER - INFO] GET_CUSTOMERS",
};

export const LoadCustomer = createAsyncAction(
  instanceActionTypes.GET_CUSTOMERS,
  async ({ pageNo, pageSize, name }) => {
    const param = {
      pageSize: pageSize,
      from: pageNo,
      parameter: {
        name: name,
        agencyId: 20,
      },
    };
    return await Post(`Customer/SearchCustomerBDA`, param, {});
  }
);
