import { combineReducers } from "redux";
import walletInstanceReducer from "./walletInstanceReducer";
import notificationReducer from "./notificationReducer";
import locationInfoTabReducer from "./locationInfoTabReducer";
import revenueTabReducer from "./revenueReducer";
import customerTabReducer from "./customerReducer";
export default combineReducers({
  walletInstances: walletInstanceReducer,
  notification: notificationReducer,
  locationInfoTab: locationInfoTabReducer,
  revenueTab: revenueTabReducer,
  customerTab: customerTabReducer,
});
