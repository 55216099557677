import React from "react";
import { Spinner } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import './Button.scss'

export function AppButton(props) {
  const { label, onClick, type, isLoading, color, disabled, size, className , style} = props;
  return (
    <Button
      label={
        <div className="d-flex justify-content-center " style={{fontSize:'12px'}}>
          <div>
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                variant="secondary"
                size="sm"
                role="status"
                aria-hidden="true"
              >
                {" "}
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              label
            )}
          </div>
        </div>
      }
      className={`min-btn-w ${className}`}
      background={color}
      size={size}
      onClick={onClick}
      type={type? type: 'button'}
      disabled={disabled}
      style={{...style}}
    />
  );
}
