export const menu = [
  // { title: "General Information", link: "/onboard", id: "general" },
  { title: "Dashboard", link: "/services/dashboard", id: "dash" },
  // { title: "Wallet Instances", children: <WalletInstances setActiveTab={setActiveTab} history={history} />, id: "wallet" },
  { title: "Invoices", link: "/services/invoice", id: "invoice" },
  { title: "Customers", link: "/services/customer", id: "customer" },
  { title: "Settings", link: "/services/settings", id: "settings" },
    { title: "Service Type", link: "/services/service-type", id: "split_type" },

  {
    title: "About",
    link: "/services/about-invoice-service",
    id: "invoice-service-about",
  },
];
export const settingsMenu = [
  { title: "Locations", link: "/services/settings/location", id: "location" },
  // { title: "Service Type", link: "/services/service-type", id: "split_type" },
  {
    title: "Settlement Account",
    link: "/services/settings/settlement-account",
    id: "split_account",
  },
  // {  link: "/services/configure-settlement-account", id: "configure_account"},
  {
    title: "Settlement Splits",
    link: "/services/settings/settlememnt-splits",
    id: "settlement_split",
  },
];
