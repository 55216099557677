import { Post, Get } from "../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const instanceActionTypes = {
    GET_SETTLEMENTS: "[SETTLEMENT - INFO] GET_SETTLEMENTS",
    GET_CURRENCIES: "[SETTLEMENT - INFO] GET_CURRENCIES",

};

// export const getCurrencies = createAsyncAction(
//   instanceActionTypes.GET_CURRENCIES,
//   async () => {
//     return await Get(
//       "/Currency/GetCurrencies",
//     );
//   }
// );
export const getAllBanks = async () => {
  return await (await Get(`/SettlementAccount/GetAllBanksBDA`)).data;
};

export const getAllAccountType = async () => {
  return await (await Get(`/SettlementAccount/GetAllAcountTypeBDA`)).data;
};

export const verifyAccount = async (accountNumber,bankId) => {
  return await (await Get(`SettlementAccount/VerifyAccountNumberBDA?accountNumber=${accountNumber}&bankId=${bankId}`)).data;
};

// export const LoadSettlement = createAsyncAction(
//   instanceActionTypes.GET_SETTLEMENTS,
//   async ({ pageNo, pageSize, qry }) => {
//     const param = {
//       pageSize: pageSize,
//       from: pageNo,
//       parameter: {
//         searchQuery: qry,
//         agencyId: 1,

//       },
//     };
//     return await Post(`SettlementAccount/SearchSettlementAccount`, param, {});
//   }
// );
export const getRevenue = async (organizationId) => {
  return await (await Get(`/Revenue/GetRevenuesBDA?OrganizationId=${organizationId}`)).data;
};

export const getSettlementAccountLocationRevenue = async (settlementAccountId, agencyId) => {
  return await (await Get(`/SettlementAccount/GetSettlementAccountLocationRevenueBDA?agencyId=${agencyId}&settlementAccountId=${settlementAccountId}`)).data;
};

export const getCurrency = async () => {
  return await (await Get(`/Currency/GetCurrenciesBDA`)).data;
};
export const LoadSettlement = async (payload) => {
  return await (await Post(`SettlementAccount/SearchSettlementAccountBDA`, payload)).data;
}

export const createSettlementAccount = async (payload) => {
  return await (await Post(`SettlementAccount/ManageSettlementAccountApprovalBDA`, payload)).data;
}
export const LoadInvoice = createAsyncAction(
  instanceActionTypes.GET_INVOICES,
  async ({ pageNo, pageSize, name }) => {
    const param = {
      pageSize: pageSize,
      from: pageNo,
      parameter: {
        agencyId: 3879,
      },
    };
    return await Post(`Customer/SearchCustomerBDA`, param, {});
  }
);



export const searchSearchSettlementSplits = async (payload) => {
  return await (await Post(`SettlementSplit/SearchSettlementSplitsBDA`, payload)).data;
}

