import React from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  PaginationWrapper,
  Pagination,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";
import TableActionDropdown from "components/tableActionDropdown/tableActionDropdown";
import EmptyState from "components/emptyState/EmptyState";
import moment from "moment";

const InvoiceTable = ({ data, filters, onPageClick }) => {
  const history = useHistory();
  const tableActions = [
    {
      id: 1,
      //   method: onViewConfiguration,
      name: "View Configuration",
    },
  ];
  return (
    <>
      {data?.results && data?.results?.length ? (
        <>
          <Table variant="unBounded">
            <thead>
              <tr className="">
                <th>Invoice Number</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Created Date</th>
                <th>Customer</th>
                <th>Revenue</th>
                <th>Amount</th>
                <th>Location</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: "none" }}>
              {data?.results?.map((invoice, idx) => (
                <tr key={invoice?.invoiceNumber}>
                  <td>{invoice?.invoiceNumber}</td>
                  <td>{invoice?.createdByName}</td>
                  <td>{invoice?.status}</td>
                  <td>{moment(invoice?.invoiceDate).format('DD/MM/YYYY')}</td>
                  <td>{invoice.customerName}</td>
                  <td>{invoice?.revenue}</td>
                  <td>{invoice.amountString}</td>
                  <td>{invoice.location}</td>
                  <td>{invoice.locationState}</td>
                  {/* <td>
                    {app.status ? Elipses("#3BA757") : Elipses("#FC4646")}&nbsp;&nbsp;
                    {app.status_text}
                  </td> */}
                  <td>
                    <div className="d-flex align-items-center justify-content-end">
                      {/* <span className="me-4 d-flex align-items-center">
                        <Switch
                          id={app.instanceId}
                          checked={app.status}
                          name={app.instanceId}
                          onChange={(e) => setDataToSwitch(app)}
                        />
                      </span>
                      <span
                        className="me-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => onEditIconClick(app)}
                      >
                        <i className="ri-pencil-line"></i>
                      </span> */}
                      {/* <TableActionDropdown
                        data={invoice}
                        actions={tableActions}
                        horizontal={false}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}{" "}
            </tbody>
          </Table>
          <div>
            <PaginationWrapper>
              <PaginationIndicator
                currentPage={filters.from}
                totalPages={Math.ceil(data.totalCount / filters.pageSize)}
              />
              <div className="pagination-container">
                <Pagination
                  className="pagination-bar"
                  currentPage={filters.from}
                  totalCount={data?.totalCount}
                  pageSize={filters?.pageSize}
                  onPageChange={(page) => onPageClick(page)}
                />
              </div>
            </PaginationWrapper>
          </div>
        </>
      ) : null}
    </>
  );
};

export default InvoiceTable;
